import React, { useState } from 'react';
import attendanceData from '../../../data/attendanceData';
import {Link} from 'react-router-dom';

const Attendance = () => {
    

    let slno =1;
    const allDates = attendanceData.flatMap((student) =>
        student.attendanceDates.map((entry) => entry.date)
    );

    const uniqueDates = [...new Set(allDates)];
    const lastTwoDigits = uniqueDates.map((date) => date.substring(8)).sort((a, b) => parseInt(a) - parseInt(b));
    
    const recordsPage = 15;
    const [currentPage , setCurrentPage] = useState(1);
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;   
    const records = attendanceData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(attendanceData.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)

    function prePage (){
        if(currentPage !== 1){
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage (student_id){
        setCurrentPage(student_id);
    }
    function nextPage (){
        if(currentPage !== npage){
            setCurrentPage(currentPage + 1)
        }
    }

    return (
        <>        
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="page-title flex-wrap">
                                <div className="input-group search-area mb-md-0 mb-3">
                                    <input type="text" className="form-control" placeholder="Search here..." />
                                    <span className="input-group-text"><Link to={"#"}>
                                        <svg wstudent_idth="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF"/>
                                        </svg>
                                    </Link></span>
                                </div>
                            </div>
                        </div>                
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="table-responsive full-data">
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                    <table className="table-responsive-lg table display" id="example-student">
                                    <thead>
                                        <tr>
                                            <th className="p-1 font-bold uppercase border hidden lg:table-cell">#</th>
                                            <th className="p-1 font-bold uppercase border hidden lg:table-cell">Name</th>
                                            {lastTwoDigits.map((number, index) => (
                                            <th key={index} className="p-1 font-bold uppercase border hidden lg:table-cell">{number}</th>
                                            ))}
                                        </tr>                
                                    </thead>
                                        <tbody>
                                            {records.map((item, ind)=>(                                
                                            <tr key={ind} >
                                                <td className="w-full lg:w-auto p-1 border border-b block lg:table-cell relative lg:static"><h6>{slno ++}</h6></td>
                                                <td className="w-full lg:w-auto p-1 border border-b block lg:table-cell relative lg:static"><h6>{item.name}</h6></td>
                                                  {item.attendanceDates.map(atus =>
                                                    <td className='w-full lg:w-auto p-1 border border-b block lg:table-cell relative lg:static text-white' style={{
                                                    backgroundColor: atus.status === 'P' ? 'green' : 'red'}}>
                                                        {atus.status}
                                                    </td>
                                                  )}
                                            </tr>
                                            ))}
                                            
                                        </tbody>
                                    </table>    
                                    <div className="d-sm-flex text-center justify-content-between align-items-center">                           
                                        <div className='dataTables_info'>
                                            Showing {lastIndex-recordsPage + 1} to{" "}
                                            {records.length < lastIndex ? records.length : lastIndex}
                                            {" "}of {records.length} entries
                                        </div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers justify-content-center"
                                            id="example-student_wrapper"
                                        >
                                            <Link
                                                className="paginate_button previous disabled"
                                                to="#"                                        
                                                onClick={prePage}
                                            >
                                                <i className="fa-solid fa-angle-left" />
                                            </Link>
                                            <span>                                      
                                                {number.map((n , i )=>(
                                                    <Link className={`paginate_button ${currentPage === n ? 'current' :  '' } `} key={i}                                            
                                                        onClick={()=>changeCPage(n)}
                                                    > 
                                                        {n}                                                

                                                    </Link>
                                                ))}
                                            </span>
                                            <Link
                                                className="paginate_button next"
                                                to="#"                                        
                                                onClick={nextPage}
                                            >
                                                <i className="fa-solid fa-angle-right" />
                                            </Link>
                                        </div>
                                    </div>   
                                </div>
                            </div>  
                        </div>    
                    </div>    
                </div>    
            </div>   
        </>
    )
}

export default Attendance
