// src/GradeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';


const GradeContext = createContext();

export function GradeProvider({ children }) {
  const [grade, setGrade] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://mglps.in/api/grades/getAllGrades.php');
      const data = await response.json();
      setGrade(data);
    } catch (error) {
      console.error('Error fetching fee data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refreshData = () => {
    fetchData();
  };

  console.log(grade);

  return (
    <GradeContext.Provider value={{ grade, loading, refreshData }}>
      {children}
    </GradeContext.Provider>
  );
}

export function useGrade() {
  const context = useContext(GradeContext);
  if (context === undefined) {
    throw new Error('useGrade must be used within a GradeProvider');
  }
  return context;
}
