import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const Uploader = () => {

    const [jsonData, setJsonData] = useState(null);
    const [uploaded, setUploaded] = useState(false);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
            const parsedJson = JSON.parse(e.target.result);
            setJsonData(parsedJson);
            } catch (error) {
            console.error('Invalid JSON file', error);
            }
        };
        reader.readAsText(file);
        }
    };

    console.log(jsonData);

    function isJSONEmpty(obj) {
        return Object.keys(obj).length === 0;
      }

    const uploadData = async () => {
        try {
            if (!jsonData) {
                console.log('No file uploaded. Skipping POST request.');
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No file uploaded. Skipping POST request.',
                    footer: '<a href="">Why do I have this issue?</a>'
                  })
              } else if (isJSONEmpty(jsonData)) {
                console.log('JSON data is empty. Skipping POST request.');
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'JSON data is empty. Skipping POST request.',
                    footer: '<a href="">Why do I have this issue?</a>'
                  })
              }
            else
            {
                for(var i = 0; i < jsonData.length; i++){
                    // Replace 'your-api-endpoint' with the actual API endpoint where you want to post the JSON data.
                const response = await axios.post('http://localhost/epromaster_v1.0/api/marks/addMarks.php', jsonData[i]);

                    if (response.status === 200) {
                        setUploaded(true);
                    }
                }
                Swal.fire({
                    icon: 'success',
                    title: 'Marks Added',
                    text: 'Assessment marks has been successfully added!',
                });
            }
        } catch (error) {
        console.error('Error uploading data:', error);
        }
        finally{
            console.log('Success')
        };
    };

    return (
        <div>
            <div className="block">
                <div className="flex mb-2">
                    <h2>Upload Students's Assessment Marks</h2>
                    <input type="file" accept=".json" onChange={handleFileUpload} />
                </div>
                <span className="mt-2"></span>
                <button className='btn btn-success' onClick={uploadData}>
                    Upload JSON
                </button>
            </div>
            <hr />
            {jsonData && (
                <div>
                <h3>Uploaded JSON Data:</h3>
                <pre>{JSON.stringify(jsonData, null, 2)}</pre>
                </div>
            )}
            {uploaded && <div>Data successfully uploaded to the server.</div>}
    </div>
    )
}

export default Uploader
