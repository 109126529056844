import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { IMAGES } from '../Dashboard/Content';
import { useGrade } from '../../../context/GradeContext';
import { useFees } from '../../../context/FeeContext';
import { useStudentsData } from '../../../context/StudentsContext';
import Swal from 'sweetalert2';

const initialFormData = {
    student_uid:'', full_name: '', dob: '', pass_photo: null, class_admitted: '',  
    sex: '', email: '', admission_status: 'complete', guardian_name: '', aadhar_no: '', 
    r_address: ''
  };

const initialPayData = {
    student_uid:'', net_amt:'', paying_amt:'', balance_amt:'', app_date:''
}

const EnrollmentModal = forwardRef((props, ref) => { 

    // Get Classes API 
    const { grade } = useGrade(); 
    const { feesData } = useFees();
    const { studentsData, refreshData } = useStudentsData();
    const [selectedGrade, setSelectedGrade ]=useState('');
    const [netAmt, setNetAmt]=useState(0);
    const [amtSubtracted, setAmtSubtracted]=useState(netAmt);
    const [suId, setSuId] = useState('');
    const [gcode, setGcode] = useState('');

    let classCount = 0;
    if(studentsData.length >0){
        classCount = studentsData.filter(student => student.class_admitted === selectedGrade).length;
    }
    
    useEffect(()=>{
        if(selectedGrade === 'Nursery'){
            setGcode(`N00S${parseInt(classCount) + 1}`);
        }else if (selectedGrade === 'KG-I'){
            setGcode(`KG1S${parseInt(classCount) + 1}`);
        }else if (selectedGrade === 'KG-II'){
            setGcode(`KG2S${parseInt(classCount) + 1}`);
        }else if (selectedGrade === 'Class-I'){
            setGcode(`C01S${parseInt(classCount) + 1}`);
        }else if (selectedGrade === 'Class-II'){
            setGcode(`C02S${parseInt(classCount) + 1}`);
        }else if (selectedGrade === 'Class-III'){
            setGcode(`C03S${parseInt(classCount) + 1}`);
        }else if (selectedGrade === 'Class-IV'){
            setGcode(`C04S${parseInt(classCount) + 1}`);
        }else if (selectedGrade === 'Class-V'){
            setGcode(`C05S${parseInt(classCount) + 1}`);
        }else{
            setGcode('');
        }
    },[selectedGrade, classCount]);

    // const admissionSerialno = gcode+'S'+ (parseInt(classCount) + 1);
    // console.log(classCount);

    // type file for image 
    const [file, setFile] = useState(null)
    
    //Modal
    const [modalBox, setModalBox] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [payData, setPayData] = useState(initialPayData);

    function generateUniqueId() {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if needed
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with leading zero if needed
        const randomNum = String(Math.floor(Math.random() * 10000)).padStart(4, '0'); // Generate a random 4-digit number and pad with leading zeros
        const uniqueId = `${day}${month}${randomNum}`;
        return uniqueId;
      }
    
    const onModalClose = () => {
        setGcode('');
        setSuId('')
        setFormData(initialFormData);
        setPayData(initialPayData);
        setModalBox(false);
    }

    useImperativeHandle(ref, () => ({
        openModal() {         
            setModalBox(true);
        },  
    }));

    //This should be outside so that unique number is generated once
    const uniqueId = generateUniqueId();//Caution! Don't put this inside useffect

    useEffect(()=>{
        if(gcode !== ''){
            setSuId(`${uniqueId}-${gcode}`);
            setFormData({
                ...formData,
                student_uid: `${uniqueId}-${gcode}`,
            });
        }
    },[gcode])
      
    
    const handleChange = (e) => {
        const { name, value, type } = e.target;   

        if (type === 'file') {
        // Convert the selected file to Base64
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setFormData({ ...formData, [name]: reader.result });
        };
        if (file) {
            reader.readAsDataURL(file);
        }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    
    useEffect(()=>{
        if(formData.class_admitted !== initialFormData.class_admitted){
            setSelectedGrade(formData.class_admitted);
        }
    },[formData.class_admitted])
    
    const selectedObj = feesData.filter(item => item.grade === `${selectedGrade}`);

    const handlePayAmt = (e) =>{
        // Get the current date
        const currentDate = new Date();

        // Format the current date as 'YYYY-MM-DD'
        const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

        setPayData({ 
            student_uid:suId,
            net_amt:netAmt, 
            paying_amt:e.target.value, 
            balance_amt:netAmt - e.target.value, 
            app_date:formattedDate
         });
    }

    useEffect(()=>{
        if(payData.paying_amt > 0){
            const balance = parseInt(netAmt) - parseInt(payData.paying_amt);
            setAmtSubtracted(balance);
        }
    },[payData.paying_amt, netAmt])

    
    useEffect(()=>{
        if(selectedObj.length >0){
            setNetAmt(selectedObj[0].total_fees);
        }
    },[selectedObj]);  

    
    const fileHandler = (e) => {       
        setFile(e.target.files[0]);	

        const { name, value, type } = e.target;   

        if (type === 'file') {
        // Convert the selected file to Base64
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setFormData({ ...formData, [name]: reader.result });
        };
        if (file) {
            reader.readAsDataURL(file);
        }
        } else {
            setFormData({ ...formData, [name]: value });
        }	
    }

    const RemoveFile = () =>{
        setFile(null)
    }

    console.log(formData);

    const handleSubmit = (e) => {
        e.preventDefault();
      
        // First POST request
        axios
          .post('https://mglps.in/api/students/post/addNewStudent.php', formData)
          .then((response) => {
            console.log('Enrollment request successful:', response.data);
            // alert(response.data.message);      
            // You can add further actions after the first successful request here
      
            // Second POST request
            axios
              .post('https://mglps.in/api/fees/makePayment.php', payData)
              .then((response) => {
                console.log('Payment request successful:', response.data);
                // Handle the response of the second request here      
                setFormData({
                    student_uid:'', full_name: '', dob: '', pass_photo: null, class_admitted: '',  
                    sex: '', email: '', admission_status: '', guardian_name: '', aadhar_no: '', 
                    r_address: ''
                });
                setPayData({
                    student_uid:'', net_amt:'', paying_amt:'', balance_amt:'', app_date:''
                });
                RemoveFile();
                setAmtSubtracted(0);
                setNetAmt(0);
                setModalBox(false);
      
                // You can add further actions after the second successful request here
              })
              .catch((secondError) => {
                console.error('Second POST request error:', secondError);
                // Handle errors for the second request here
              })
              .finally (() =>{
                Swal.fire({
                    icon: 'success',
                    title: 'Admission successfull',
                    text: 'Payment completed successfully!',
                });
                refreshData();
                setModalBox(false);
            });
              
          })
          .catch((error) => {
            console.error('First POST request error:', error);
            // Handle errors for the first request here
          });
      };
      

    return (
        <Modal  onHide={setModalBox} size='xl' show={modalBox} centered> 
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Enrollment Form</h5>
                    <button type="button" className="btn-close"  onClick={onModalClose}></button>
                </div>
                <div className="modal-body">
                    <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-4 d-block">
                                            <div>
                                                <label className="form-label text-primary">Photo<span className="required">*</span></label>
                                                <div className="avatar-upload">
                                                    <div className="avatar-preview">
                                                        <div id="imagePreview"                                                 
                                                            style={{backgroundImage: file ? "url(" + URL.createObjectURL(file) + ")" : "url(" + IMAGES.noimage +")" }}
                                                        > 			
                                                        </div>
                                                    </div>
                                                    <div className="change-btn mt-2 mb-lg-0 mb-3">
                                                        <input type='file' className="form-control d-none" name="pass_photo" onChange={fileHandler}  id="imageUpload" accept=".png, .jpg, .jpeg" />
                                                        <label htmlFor="imageUpload" className="dlab-upload mb-0 btn btn-primary btn-sm">Choose File</label>
                                                        <Link to={"#"} className="btn btn-danger light remove-img ms-2 btn-sm" onClick={RemoveFile}>Remove</Link>
                                                    </div>
                                                </div>	
                                            </div>
                                            <span className='p-4'></span>
                                            <div className="flex flex-wrap mx-3 mb-1">
                                                <div className="file-input w-full px-9 mb-1 md:mb-0">
                                                <label className="form-label text-primary" htmlFor="grid-first-name">
                                                    Gender
                                                </label>
                                                <div className='flex'>
                                                    <label className="flex form-label text-primary p-2">
                                                    <input
                                                        className='p-4'
                                                        type="checkbox"
                                                        name="sex"
                                                        value="male"
                                                        checked={formData.sex === 'male'}
                                                        onChange={handleChange}
                                                    />
                                                    <span className='p-2'>Male</span>
                                                    </label>
                                                    <span className="p-2"></span>
                                                    <label className="flex form-label text-primary p-2">
                                                    <input
                                                        className='p-4'
                                                        type="checkbox"
                                                        name="sex"
                                                        value="female"
                                                        checked={formData.sex === 'female'}
                                                        onChange={handleChange}
                                                    />
                                                    <span className='p-2'>Female</span>
                                                    </label>
                                                </div>
                                                </div>
                                            </div>
                                            <div>
                                                <label className="form-label text-primary">Net Amount({netAmt})<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput1"  
                                                    value={amtSubtracted === 0 ? netAmt: amtSubtracted}
                                                    readOnly
                                                />	
                                            </div>
                                            <span className='p-4'></span>
                                            <div>
                                                <label className="form-label text-primary">Paying Amount<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput1"                                                     
                                                    name="paying_amt"
                                                    value={payData.paying_amt}
                                                    onChange={handlePayAmt}
                                                    required
                                                />	
                                            </div>
                                        </div>
                                        <div className="col-xl-9 col-lg-8">
                                            <div className="row">
                                                <div className="col-xl-6 col-sm-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Full Name<span className="required">*</span></label>
                                                        <input                                                     
                                                            type="text" 
                                                            className="form-control" 
                                                            id="exampleFormControlInput1"                                                     
                                                            name="full_name"
                                                            value={formData.full_name}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label  className="form-label text-primary">Date & Place of Birth<span className="required">*</span></label>
                                                        <div className="d-flex">                                                    
                                                            <input                                                     
                                                                type="date" 
                                                                className="form-control" 
                                                                id="exampleFormControlInput7"                                                     
                                                                name="dob"
                                                                value={formData.dob}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                            <input                                                     
                                                                type="text" 
                                                                className="form-control w-50 ms-3" 
                                                                id="exampleFormControlInput7"                                                     
                                                                name="place"
                                                                value={formData.place}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Email<span className="required">*</span></label>
                                                        <input                                                    
                                                            type="email" 
                                                            className="form-control" 
                                                            id="exampleFormControlInput3" 
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlTextarea1" className="form-label text-primary">Address<span className="required">*</span></label>
                                                        <textarea                                                    
                                                            type="text" 
                                                            className="form-control" 
                                                            id="exampleFormControlTextarea1" 
                                                            rows="5"
                                                            name="r_address"
                                                            value={formData.r_address}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-sm-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput4" className="form-label text-primary">Class<span className="required">*</span></label>
                                                        {/* <input type="text" className="form-control" id="exampleFormControlInput4" placeholder="Wally" /> */}
                                                        <select name="class_admitted" value={formData.class_admitted} onChange={handleChange} className="form-control" id="exampleFormControlInput4" required>
                                                            {/* <option value="">--Class--</option> */}
                                                            {grade.map((item) => <option value={item.class_options}>{item.class_label}</option>)}
                                                        </select>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput5" className="form-label text-primary">Guardian Name<span className="required">*</span></label>
                                                        <input                                                    
                                                            type="text" 
                                                            className="form-control" 
                                                            id="exampleFormControlInput5" 
                                                            name="guardian_name"
                                                            value={formData.guardian_name}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Phone Number<span className="required">*</span></label>
                                                        <input                                                    
                                                            type="number" 
                                                            className="form-control" 
                                                            id="exampleFormControlInput6" 
                                                            name="contact_no"
                                                            value={formData.contact_no}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Aadhar Number<span className="required">*</span></label>
                                                        <input                                                    
                                                            type="number" 
                                                            className="form-control" 
                                                            id="exampleFormControlInput6" 
                                                            name="aadhar_no"
                                                            value={formData.aadhar_no}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success light" onClick={handleSubmit}>Save</button>
                        <button type="button" className="btn btn-danger light" onClick={onModalClose}>Close</button>
                    </div>
            </div>                
        </Modal>
    )
})

export default EnrollmentModal
