const attendanceData = [
    {
        student_id: 'mglps01070123',
        name: 'John Doe',
        rollNo:'1',  
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'A' },
            { date: '2023-07-06', status: 'A' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
            // Add more attendance entries for John Doe if needed
        ],
    },
    {
        student_id: 'mglps01070124',
        name: 'Jane Smith',
        rollNo:'2',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'A' },
            { date: '2023-07-06', status: 'A' },
            { date: '2023-07-07', status: 'A' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070125',
        name: 'Smith Jim',
        rollNo:'3',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070126',
        name: 'Smith Sangma',
        rollNo:'4',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070127',
        name: 'Rimchi Jim',
        rollNo:'5',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070128',
        name: 'Sanam Marak',
        rollNo:'6',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070129',
        name: 'Nochi Sangma',
        rollNo:'7',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'A' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'A' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070110',
        name: 'Jeni Sangma',
        rollNo:'8',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'A' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'A' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070111',
        name: 'Moni Sangma',
        rollNo:'9',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'A' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'A' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070112',
        name: 'Rina Marak',
        rollNo:'10',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'A' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070113',
        name: 'Junia Momin',
        rollNo:'11',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'A' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'A' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070114',
        name: 'Rosy Sangma',
        rollNo:'12',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'A' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070115',
        name: 'Albat Marak',
        rollNo:'13',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'A' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'A' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070116',
        name: 'Jakus Marak',
        rollNo:'14',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'A' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070117',
        name: 'Jimcrom Moiu',
        rollNo:'15',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'A' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070118',
        name: 'Sania Mirja',
        rollNo:'16',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'A' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'A' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070119',
        name: 'Jami Sangma',
        rollNo:'17',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'A' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070120',
        name: 'Sarmila Marak',
        rollNo:'18',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'A' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'A' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070021',
        name: 'Jipro Sangma',
        rollNo:'19',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'A' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070022',
        name: 'Minua Sangma',
        rollNo:'20',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'A' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070023',
        name: 'Kisan James',
        rollNo:'21',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'A' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070024',
        name: 'Janim Khongjoh',
        rollNo:'22',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'P' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'A' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'A' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070025',
        name: 'Mstudent_idu Das',
        rollNo:'23',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'A' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'P' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
    {
        student_id: 'mglps01070026',
        name: 'Domin Doe',
        rollNo:'24',
        attendanceDates: [
            { date: '2023-07-01', status: 'P' },
            { date: '2023-07-02', status: 'A' },
            { date: '2023-07-03', status: 'P' },
            { date: '2023-07-04', status: 'P' },
            { date: '2023-07-05', status: 'P' },
            { date: '2023-07-06', status: 'P' },
            { date: '2023-07-07', status: 'P' },
            { date: '2023-07-08', status: 'P' },
            { date: '2023-07-09', status: 'P' },
            { date: '2023-07-10', status: 'P' },
            { date: '2023-07-11', status: 'P' },
            { date: '2023-07-12', status: 'P' },
            { date: '2023-07-13', status: 'P' },
            { date: '2023-07-14', status: 'P' },
            { date: '2023-07-15', status: 'P' },
            { date: '2023-07-16', status: 'P' },
            { date: '2023-07-17', status: 'P' },
            { date: '2023-07-18', status: 'P' },
            { date: '2023-07-19', status: 'A' },
            { date: '2023-07-20', status: 'P' },
            { date: '2023-07-21', status: 'P' },
            { date: '2023-07-22', status: 'P' },
        // Add more attendance entries for Jane Smith if needed
        ],
    },
];

export default attendanceData;