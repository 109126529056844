import React from 'react'

// Subjects Data as an array of objects
const subjectsData = [
  {
    subjectID: 101,
    subjectName: "Mathematics",
    description: "Calculus, Algebra, Geometry",
  },
  {
    subjectID: 102,
    subjectName: "English",
    description: "Literature, Grammar, Composition",
  },
  {
    subjectID: 103,
    subjectName: "Science",
    description: "Physics, Chemistry, Biology",
  },
  {
    subjectID: 104,
    subjectName: "History",
    description: "World History, American History",
  },
  {
    subjectID: 105,
    subjectName: "Computer Science",
    description: "Programming, Algorithms, Databases",
  },
  {
    subjectID: 106,
    subjectName: "Physical Education",
    description: "Sports, Fitness, Health Education",
  },
];

// Classes Data as an array of objects
const classesData = [
  {
    classID: 201,
    className: "Math A",
    subjectID: 101,
    teacherName: "Mr. Smith",
    roomNumber: "Room 301",
    schedule: ["Mon", "Wed", "Fri"],
  },
  {
    classID: 202,
    className: "English B",
    subjectID: 102,
    teacherName: "Ms. Johnson",
    roomNumber: "Room 202",
    schedule: ["Tue", "Thu"],
  },
  {
    classID: 203,
    className: "Science C",
    subjectID: 103,
    teacherName: "Mr. Davis",
    roomNumber: "Room 401",
    schedule: ["Mon", "Wed", "Fri"],
  },
  {
    classID: 204,
    className: "History D",
    subjectID: 104,
    teacherName: "Mrs. Lee",
    roomNumber: "Room 102",
    schedule: ["Tue", "Thu"],
  },
  {
    classID: 205,
    className: "Comp Sci X",
    subjectID: 105,
    teacherName: "Mr. Brown",
    roomNumber: "Room 501",
    schedule: ["Mon", "Tue", "Wed"],
  },
  {
    classID: 206,
    className: "PE Y",
    subjectID: 106,
    teacherName: "Coach Adams",
    roomNumber: "Gymnasium",
    schedule: ["Tue", "Thu"],
  },
];


const ManageClass = () => {

  return (
    <>
      <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Subject List</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                            <table className="border-collapse w-full">
                  <thead>
                      <tr>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">#</th>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Subject Name</th>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Description</th>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Status</th>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Actions</th>
                      </tr>                
                  </thead>
                  <tbody>
                    {/* Render the current items on the current page */}
                    {subjectsData.map((subject) => {
                      return (<tr key={subject.subjectID} className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                        {/* Table body cells */}
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">{subject.subjectID}</td>
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">{subject.subjectName}</td>
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b  block lg:table-cell relative lg:static">{subject.description}</td>
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b  block lg:table-cell relative lg:static">
                          <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Status</span>
                          <span className="rounded bg-green-400 py-1 px-3 text-xs font-bold">Effective</span>
                      </td>
                      <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                          <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Actions</span>
                          <div className='flex justify-center items center p-1'>
                          <a href="#" className="text-blue-400 hover:text-blue-600 underline pl-2">Edit</a>
                          <a href="#" className="text-blue-400 hover:text-blue-600 underline pl-2">View</a>
                          <a href="#" className="text-blue-400 hover:text-blue-600 underline pl-2">Download</a>
                          </div>
                      </td>
                      </tr>)
                    })}
                  </tbody>
                </table>       

                {/* *****************************                         */}

                <table className="border-collapse w-full">
                  <thead>
                      <tr>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">#</th>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">subject</th>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">CODE</th>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Grade</th>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Date</th>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Status</th>
                          <th className="p-1 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Actions</th>
                      </tr>                
                  </thead>
                  <tbody>
                    {/* Render the current items on the current page */}
                    {classesData.map((subject) => {
                      return (<tr key={subject.classID} className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                        {/* Table body cells */}
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">{subject.classID}</td>
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">{subject.className}</td>
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">{subject.subjectID}</td>
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b  block lg:table-cell relative lg:static">{subject.teacherName}</td>
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b  block lg:table-cell relative lg:static">{subject.roomNumber}</td>
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b  block lg:table-cell relative lg:static">{subject.schedule}</td>
                        <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b  block lg:table-cell relative lg:static">
                          <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Status</span>
                          <span className="rounded bg-green-400 py-1 px-3 text-xs font-bold">Effective</span>
                      </td>
                      <td className="w-full lg:w-auto p-1 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                          <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Actions</span>
                          <div className='flex justify-center items center p-1'>
                          <a href="#" className="text-blue-400 hover:text-blue-600 underline pl-2">Edit</a>
                          <a href="#" className="text-blue-400 hover:text-blue-600 underline pl-2">View</a>
                          <a href="#" className="text-blue-400 hover:text-blue-600 underline pl-2">Delete</a>
                          </div>
                      </td>
                      </tr>)
                    })}
                  </tbody>
                </table>   
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
    </>
  )
}

export default ManageClass
