import React, { useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import { useSubjects } from '../../../context/SubjectContext';
import { useGrade } from '../../../context/GradeContext';
import EditSubjectModal from '../Modals/EditSubjectModal';
import DeleteSubjectModal from '../Modals/DeleteSubjectModal';
import AddSubjectModal from '../Modals/AddSubjectModal';
import Form from 'react-bootstrap/Form';

const ManageSubjects = () => {

    // get fee structures 
    const { subList, loading, refreshData } = useSubjects(); 
    const { grade } = useGrade();
    const [selectedGrade, setSelectedGrade] = useState('all');
    const [displayList, setDisplayList] = useState(subList);
    
    const handleClassSelection = (e) =>{
        setSelectedGrade(e.target.value);
    }

    useEffect(()=>{
        if(selectedGrade !=='all'){
            const filteredSublist = subList.filter(gradefor => gradefor.subject_for_class === selectedGrade);
            setDisplayList(filteredSublist);
        }else{
            setDisplayList(subList);
        }
    },[selectedGrade, subList])
    console.log(displayList);

    let slno = 1;

    const childRef1 = useRef();
    // const childRef2 = useRef();
    const childRef3 = useRef();	
    const childRef4 = useRef();	

    const recordsPage = 10;
    const [currentPage , setCurrentPage] = useState(1);
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;   
    const records = displayList.slice(firstIndex, lastIndex);
    const npage = Math.ceil(displayList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)

   
    function prePage (){
        if(currentPage !== 1){
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage (student_id){
        setCurrentPage(student_id);
    }
    function nextPage (){
        if(currentPage !== npage){
            setCurrentPage(currentPage + 1)
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Subject Management</h5>
                            <div className='d-flex'>
                                <Form.Select size="sm" style={{width:'120px'}} value={selectedGrade} onChange={handleClassSelection}>
                                    {grade.map((item) => <option value={item.class_options}>{item.class_label}</option>)}
                                </Form.Select>
                                <span className="p-1"></span>  
                                <button type="button" className="btn btn-primary" onClick={() => childRef1.current.openModal()}>
                                    + New Subject
                                </button>
                                <span className='p-1'></span>
                                <button type="button" className="btn btn-success" onClick={refreshData} disabled={loading}>
                                    Refresh Data
                                </button>
                            </div>
                        </div>
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <div>
                        {records.length > 0
                        ?
                        (<div className="card-body">
                            <div className="row">
                            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                                <div className="table-responsive full-data">
                                    <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                        <table className="table-responsive-lg table display dataTablesCard student-tab dataTable no-footer" subject_id="example-student">
                                            <thead>
                                                <tr>
                                                    <th>Sl No</th>
                                                    <th>Subject</th>
                                                    <th>Code</th>
                                                    <th>Grade</th>
                                                    <th>Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {records.map((item)=>(                                
                                                    <tr key={item.subject_id}>
                                                        
                                                        <td>
                                                            <div className="trans-list">
                                                                <h4>{slno++}</h4>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="date">{item.suject_name}</div>
                                                        </td>
                                                        {/* <td><h6 className="mb-0">{item.suject_name}</h6></td> */}
                                                        <td><h6 className="mb-0">{item.subject_code}</h6></td>
                                                        <td><h6 className="mb-0">{item.subject_for_class}</h6></td>
                                                        <td><h6 className="mb-0">{item.added_on}</h6></td>
                                                        <td>
                                                            <div className="d-flex">
                                                                {/* <div className="icon-box icon-box-sm bg-light me-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef2.current.openModal(item.subject_id)} ><path d="M120-120v-320h80v184l504-504H520v-80h320v320h-80v-184L256-200h184v80H120Z"/></svg>
                                                                </div> */}
                                                                <div className="icon-box icon-box-sm bg-light me-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef3.current.openModal(item.subject_id)}><path d="M200-200h56l345-345-56-56-345 345v56Zm572-403L602-771l56-56q23-23 56.5-23t56.5 23l56 56q23 23 24 55.5T829-660l-57 57Zm-58 59L290-120H120v-170l424-424 170 170Zm-141-29-28-28 56 56-28-28Z"/></svg>
                                                                </div>
                                                                <div className="icon-box icon-box-sm bg-light">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef4.current.openModal(item.subject_id)} ><path d="M600-240v-80h160v80H600Zm0-320v-80h280v80H600Zm0 160v-80h240v80H600ZM120-640H80v-80h160v-60h160v60h160v80h-40v360q0 33-23.5 56.5T440-200H200q-33 0-56.5-23.5T120-280v-360Zm80 0v360h240v-360H200Zm0 0v360-360Z"/></svg>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>  
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">                           
                                        <div className='dataTables_info'>
                                            Showing {lastIndex-recordsPage + 1} to{" "}
                                            {subList.length < lastIndex ? subList.length : lastIndex}
                                            {" "}of {subList.length} entries
                                        </div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers justify-content-center"
                                            id="example-student_wrapper"
                                        >
                                            <Link
                                                className="paginate_button previous disabled"
                                                to="#"                                        
                                                onClick={prePage}
                                            >
                                                <i className="fa-solid fa-angle-left" />
                                            </Link>
                                            <span>                                      
                                                {number.map((n , i )=>(
                                                    <Link className={`paginate_button ${currentPage === n ? 'current' :  '' } `} key={i}                                            
                                                        onClick={()=>changeCPage(n)}
                                                    > 
                                                        {n}                                                

                                                    </Link>
                                                ))}
                                            </span>
                                            <Link
                                                className="paginate_button next"
                                                to="#"                                        
                                                onClick={nextPage}
                                            >
                                                <i className="fa-solid fa-angle-right" />
                                            </Link>
                                        </div>
                                    </div>   
                                    </div>
                                </div>  
                            </div> 
                            </div>
                        </div>)
                        :(<div className="card-body">
                            <div className="row">
                                <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s"> 
                                    <h3>No records found</h3>
                                </div>
                            </div>
                        </div>
                        )
                        }
                        </div>
                        )}
                    </div>
                </div>
            </div>  
            <AddSubjectModal ref={childRef1} />      
            {/* <DeleteConfirmModal ref={childRef2} />           */}
            <EditSubjectModal ref={childRef3} /> 
            <DeleteSubjectModal ref={childRef4} />          
        </>
  )
}

export default ManageSubjects
