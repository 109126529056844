import React,{useState, useEffect} from 'react';
import axios from 'axios';

const AddFees = () => {
 
    // Get Classes API 
    const GET_GRADES = 'https://mglps.in/api/grades/getAllGrades.php';

    //Post API
    const ADD_FEE_STRUCTURE = 'https://mglps.in/api/fees/addFeeStructure.php';

    // get fee structures 
    const GET_FEE_STRUCTURE ='https://mglps.in/api/fees/getFeeStructures.php';
    
    const [grades, setGrades] = useState([]);
    const [feeStructures, setFeeStructures]= useState([]);
    const [selectedGrade, setSelectedGrade ] = useState('all');
    const [flag, setFlag] = useState(0);
    const [canSave, setCanSave ] = useState(false);

    //fetch grades
    useEffect(() =>{
        const fetchClasses = async() =>{
            const res = await axios.get(GET_GRADES);
            setGrades(res.data);
          }
          fetchClasses();
    },[])

    // fetch fee structures 
    useEffect(() =>{
        const fetchFeeStructures = async() =>{
            const res = await axios.get(GET_FEE_STRUCTURE);
            setFeeStructures(res.data);
          }
          fetchFeeStructures();
    },[flag])

    let feeStructureExist = [];
    if(feeStructures.length > 0){
        feeStructureExist = feeStructures.filter(std =>std.grade == selectedGrade && std.added_on.slice(0, 4)== "2023");
    }
   
   
    useEffect(()=>{
        if(feeStructureExist.length !== 0){
                console.log(feeStructureExist.length);
                setCanSave(false);
            }else{
                setCanSave(true);
            }
    }, [feeStructureExist])

    const [formData, setFormData] = useState({
        grade: selectedGrade,
        admission_fee: 0,
        tuition_fee:0,
        transport_fee:0,
        sport_fee: 0,
        library_fee: 0,
        idcard_fee: 0,
        computer_lab_fee: 0,
        exam_fee: 0,
        marksheet_fee: 0,
        miscellaneous_fee: 0,
        record_maintenance_fee: 0,
        tie_belt_fee: 0,
        extra_curicular: 0,
        total_fees: 0
      });

      const handleGradeSelection = (e)=>{
          setSelectedGrade(e.target.value);
        }
        

    const handleChange = (event) => {
        const { name, value } = event.target;
        // Update formData with the changed field value
        setFormData({
          ...formData,
          [name]: value, // Ensure the values are treated as numbers
        });
      };
    
              
        
        const arr = [parseInt(formData.admission_fee), parseInt(formData.tuition_fee)*12, parseInt(formData.transport_fee),
            parseInt(formData.sport_fee), parseInt(formData.library_fee), parseInt(formData.idcard_fee), parseInt(formData.computer_lab_fee),
            parseInt(formData.exam_fee), parseInt(formData.marksheet_fee), parseInt(formData.miscellaneous_fee), parseInt(formData.record_maintenance_fee)*12, 
            parseInt(formData.tie_belt_fee), parseInt(formData.extra_curicular)];
            
        const netAmount = arr.reduce((prev, cur) => prev + cur); //Total value of fees

        const updateTotalFees = () => {
            setFormData({
              ...formData,
              total_fees: netAmount,
              grade: selectedGrade,
            });
            setFlag(1);
            if(selectedGrade ==='all'){
                alert('Please select class');
                setFlag(0);
            }
          };

    //   console.log(formData.total_fees);
    
      const handleSubmit = async (event) => {
        event.preventDefault();  
        console.log(canSave);
        if(!canSave){
            alert('Already exist, you can only update it...');
        } else {           
        // Assuming you have an API endpoint to send the data to, use fetch or a library like axios
            try {                
                  console.log(formData); 
                const response = await fetch(ADD_FEE_STRUCTURE, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
            });
        
            if (response.ok) {
                // Data successfully posted to the database
                // Optionally, you can reset the form fields here
                setFlag(0);
                alert('Fees added successfully...');
                console.log(response);
                setFormData({
                    grade: '',
                    admission_fee: 0,
                    tuition_fee: 0,
                    transport_fee: 0,
                    sport_fee: 0,
                    library_fee: 0,
                    idcard_fee: 0,
                    computer_lab_fee: 0,
                    exam_fee: 0,
                    marksheet_fee: 0,
                    miscellaneous_fee: 0,
                    record_maintenance_fee: 0,
                    tie_belt_fee: 0,
                    extra_curicular: 0,
                    total_fees: 0
                });
            } else {
                console.error('Failed to post fee structure');
            }
            } catch (error) {
                console.error('Error:', error);
            }
        }
      };
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">New Fee Structure</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 d-block">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput4" className="form-label text-primary">Class<span className="required">*</span></label>
                                        <select value={selectedGrade} onChange={handleGradeSelection} className="form-control" id="exampleFormControlInput4">
                                            {/* <option value="">--Class--</option> */}
                                            {grades.map((item) => <option value={item.class_options}>{item.class_label}</option>)}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <h1>Total Amount ₹:{netAmount}</h1>
                                    </div>
                                    <div className="mb-3">
                                        <button className='btn btn-primary' onClick={updateTotalFees}>Update Total</button>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Admission Fee<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput1" 
                                                    name="admission_fee"
                                                    value={formData.admission_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Monthly Tution Fee (Auto multiplied x12 months)<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput1"                                                     
                                                    name="tuition_fee"
                                                    value={formData.tuition_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Transportation Fee<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="transport_fee"
                                                    value={formData.transport_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Game & Sport Fee<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3" 
                                                    name="sport_fee"
                                                    value={formData.sport_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Examination Fee<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="exam_fee"
                                                    value={formData.exam_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Extra Curicular Activities<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="extra_curicular"
                                                    value={formData.extra_curicular}
                                                    onChange={handleChange}
                                                    required/>
                                                    
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Miscellaneous Fee<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="miscellaneous_fee"
                                                    value={formData.miscellaneous_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Accessories (Tie, Belt & Shocks)<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                    
                                                    name="tie_belt_fee"
                                                    value={formData.tie_belt_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput5" className="form-label text-primary">Library Fee (Refundable)<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput5" 
                                                    name="library_fee"
                                                    value={formData.library_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Identification Card<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="idcard_fee"
                                                    value={formData.idcard_fee}
                                                    onChange={handleChange}
                                                    required/>
                                                    
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Computer Lab<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                    
                                                    name="computer_lab_fee"
                                                    value={formData.computer_lab_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Marksheet<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="marksheet_fee"
                                                    value={formData.marksheet_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Record Maintainance (Auto multiplied x12 months)<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="record_maintenance_fee"
                                                    value={formData.record_maintenance_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Net Amount<span className="required">*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="total_fees"
                                                    value={formData.total_fees}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <button className="btn btn-outline-primary me-3">Save as Draft</button>
                                <button className="btn btn-primary" type="button" onClick={handleSubmit} disabled={flag === 0}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </>
    );
};


export default AddFees
