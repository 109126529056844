import { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export function useDataContext() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
    const [data, setData] = useState([]);
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [crudFlag, setCrudFlag] = useState(false);
  
    // Define a function to toggle the shouldRefetch flag
    const toggleRefetchFlag = () => {
      setShouldRefetch(!shouldRefetch);
    };

    const toggleCrudFlag = () => {
      setCrudFlag(!crudFlag);
    };
  
    return (
      <DataContext.Provider value={{ data, shouldRefetch, toggleRefetchFlag, toggleCrudFlag  }}>
        {children}
      </DataContext.Provider>
    );
  }
  