import React, { useRef} from 'react';
import EditFeeModal from '../Modals/EditFeeModal';
import NewSessionModal from '../Modals/NewSessionModal';
import { useAcademic } from '../../../context/AcademicContext';
import EditAcademicModal from '../Modals/EditAcademicModal';

const ManageSession = () => {

    const { academic, loading, refreshData } = useAcademic(); 

    
    let slno = 1;

    const childRef1 = useRef();
    const childRef2 = useRef();

    return (
            <>
              <div className="row">
                  <div className="col-xl-12">
                      <div className="card">
                          <div className="card-header">
                              <h5 className="mb-0">Session Management</h5>
                              <span className='p-2'></span>
                              <div>
                              <button className='btn btn-success' onClick={refreshData} disabled={loading}>
                                Refresh Data
                              </button>
                              <span className='p-2'></span>
                              <button className='btn btn-success' onClick={() => childRef1.current.openModal()}>
                                Add New +
                              </button>
                              </div>
                          </div>
                        <div>
                          {academic.length > 0
                            ?
                          (<div className="card-body">
                              <div className="row">
                                <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                                  <div className="table-responsive full-data">
                                      <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                          <table className="table-responsive-lg table display dataTablesCard student-tab dataTable no-footer" fee_id="example-student">
                                              <thead>
                                                  <tr>
                                                      <th>Sl No</th>
                                                      <th>Academic Year</th>
                                                      <th>Started</th>
                                                      <th>Status</th>
                                                      <th>Closed</th>
                                                      <th>Update</th>
                                                      <th>Action</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {academic.map((item)=>(                                
                                                      <tr key={item.ss_id}>
                                                         
                                                          <td>
                                                              <div className="trans-list">
                                                                  <h4>{slno++}</h4>
                                                              </div>
                                                          </td>
                                                          <td>
                                                              <div className="date">{item.academic_year}</div>
                                                          </td>
                                                          <td><h6 className="mb-0">{item.opened_on}</h6></td>
                                                          <td><h6 className={`badge text-capitalize bg-${item.sess_status === 'active' ? 'success' : 'warning'}`}>{item.sess_status}</h6></td>
                                                          <td><h6 className="mb-0">{item.clossed_on}</h6></td>
                                                          <td><h6 className="mb-0">{item.edited_on !== null ? item.edited_on : 'Not updated'}</h6></td>
                                                          <td>
                                                            <div className="d-flex">
                                                                <div className="icon-box icon-box-sm bg-light me-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef2.current.openModal(item.ss_id)} ><path d="M120-120v-320h80v184l504-504H520v-80h320v320h-80v-184L256-200h184v80H120Z"/></svg>
                                                                </div>
                                                            </div>
                                                          </td>
                                                         
                                                      </tr>
                                                  ))}
                                              </tbody>
                                          </table>    
                                          <div className="d-sm-flex text-center justify-content-between align-items-center">                           
                                              
                                              <div
                                                  className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                  id="example-student_wrapper"
                                              >
                                              </div>
                                          </div>   
                                      </div>
                                  </div>  
                              </div> 
                              </div>
                          </div>)
                            :(<div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s"> 
                                        <h3>No records found</h3>
                                    </div>
                                </div>
                            </div>
                            )
                            }
                            </div>
                      </div>
                  </div>
              </div>          
              <NewSessionModal ref={childRef1} />         
              <EditAcademicModal ref={childRef2} />         
          </>
    )
}

export default ManageSession
