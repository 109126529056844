// src/SfbContext.js   -> Students Fee Balance
import React, { createContext, useContext, useState, useEffect } from 'react';


const SfbContext = createContext();

export function SfbProvider({ children }) {
  const [sfb, setSfb] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://mglps.in/api/payments/getBalances.php');
      const data = await response.json();
      setSfb(data);
    } catch (error) {
      console.error('Error fetching fee sfb data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refreshData = () => {
    fetchData();
  };

  console.log(sfb);

  return (
    <SfbContext.Provider value={{ sfb, loading, refreshData }}>
      {children}
    </SfbContext.Provider>
  );
}

export function useSfb() {
  const context = useContext(SfbContext);
  if (context === undefined) {
    throw new Error('useSfb must be used within a SfbProvider');
  }
  return context;
}
