import React, { useState, useRef, useEffect } from 'react';
import { useStudentsData } from '../../../context/StudentsContext';

function FormUtility({ setFormData, setCanSave, canSave }) {

    const { studentsData } = useStudentsData(); 
    const [text, setText] = useState('');
    const [uniqueStudents, setUniqueStudents] = useState([]);
    
    const studentUIDs = studentsData.map(item => item.student_uid);
    const filteredStudentIds = studentUIDs.filter(id => id && id.length === 8);
    console.log(filteredStudentIds);

    // get current date 
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`;
    // *********************
    const handleTextAreaChange = (event) => {
      const inputValue = event.target.value;

      // Remove any existing commas and split the input text into an array of words
      const words = inputValue.split(',').map((word) => word.trim());

      // Filter out duplicate words using a Set
      const uniqueWords = [...new Set(words)];

      // Join the unique words with commas
      const newText = uniqueWords.join(',');

      setText(newText);

      // Add a comma after every 10 characters
      const formattedText = newText.replace(/(.{8})/g, '$1,');

      // Create an array of objects for every 10 characters
      const uniqueStudentsArray = formattedText
        .split(',')
        .map((student_uid) => student_uid.trim())
        .filter((student_uid) => student_uid !== '')
        .map((student_uid) => ({
          student_uid,
          attendance_date: formattedDate, // You can set the desired date here
          attendance_status: 'P', // You can set the desired status here
        }));

      setUniqueStudents(uniqueStudentsArray);
  };

  // for combined array 
    // combined array 
    // Create a new array to store the combined data
    const combinedArray = [];

    // Iterate through the first array
    for (const student_uid of filteredStudentIds) {
      // Check if the student_uid exists in the second array

      const foundItem = uniqueStudents.find(item => item.student_uid === student_uid);
      console.log(foundItem);
      // If found, push it to the combined array with the same data
      if (foundItem) {
        combinedArray.push(foundItem);
      } else {
        // If not found, create a new object with "A" attendance status and push it
        combinedArray.push({
          student_uid: student_uid,
          attendance_date: formattedDate,
          attendance_status: "A",
        });
      }
    }

    console.log(combinedArray);
  //end combined array

  const buttonRef = useRef();

  useEffect(() => {
    if (canSave) {
      // Trigger a click event on the button when the flag becomes true
      buttonRef.current.click();
    }
  }, [canSave]);

  const [dataText, setDataText] = useState(''); 

  const handleRemoveDuplicates = () => {
    // Get the unique student UIDs from the current uniqueStudents state
    const uniqueStudentUIDs = new Set(uniqueStudents.map((student) => student.student_uid));

    // Create a new array of objects with unique student UIDs
    const uniqueStudentsArray = [...uniqueStudentUIDs].map((student_uid) => ({
      student_uid,
      attendance_date: formattedDate, // You can set the desired date here
      attendance_status: 'P', // You can set the desired status here
    }));

    // Update the state with the new array without duplicates
    setUniqueStudents(uniqueStudentsArray);
    setCanSave(true);
    setFormData(combinedArray);
  };

  const formatDataAsText = (uniqueStudents) => {
    let text = '';
    uniqueStudents.forEach(item => {
      text += `Student UID: ${item.student_uid}, Date: ${item.attendance_date}, Status: ${item.attendance_status}\n`;
    });
    return text;
  };

  // Event handler for the button click
  const handleButtonClick = () => {
    const formattedData = formatDataAsText(uniqueStudents);
    setDataText(formattedData);
  };

  const clearAllData = () => {
    setDataText('');
    setCanSave(false);
  }


  return (
    <>
      <div className="d-flex">
        <div className="d-block p-4">
            <div>
              <textarea
                value={text}
                onChange={handleTextAreaChange}
                placeholder="Enter text..."
                rows="10"
                cols="40"
              />
            </div>
            <span className='p-4'></span>
            <div>
              <button type="button" className="btn btn-success light" onClick={handleRemoveDuplicates}>Check</button>
            </div>
        </div>
        {/* ********** */}
        <div className='d-block p-4'>
         <div>
          <textarea
              rows="10"
              cols="40"
              value={dataText}
              readOnly
            />
         </div>
          <span className='p-4'></span>
         <div>
          <button onClick={handleButtonClick} ref={buttonRef} style={{display:"none"}}>Display Data</button>
          <button type="button" className="btn btn-success light" onClick={clearAllData}>Clear all</button>           
         </div>
        </div>
      </div>
    </>
  );
}


export default FormUtility
