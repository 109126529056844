// src/StudentsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';


const StudentsContext = createContext();

export function StudentsProvider({ children }) {
  const [studentsData, setStudentsData] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://mglps.in/api/students/get/getStudentDetail.php');
      const data = await response.json();
      setStudentsData(data);
    } catch (error) {
      console.error('Error fetching students data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refreshData = () => {
    fetchData();
  };

  console.log(studentsData);

  return (
    <StudentsContext.Provider value={{ studentsData, loading, refreshData }}>
      {children}
    </StudentsContext.Provider>
  );
}

export function useStudentsData() {
  const context = useContext(StudentsContext);
  if (context === undefined) {
    throw new Error('useStudentsData must be used within a StudentsProvider');
  }
  return context;
}
