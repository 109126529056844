import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {Modal} from 'react-bootstrap';
import axios from 'axios';

const NewSessionModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [formData, setFormData] = useState({
        academic_year:'', opened_on:'', clossed_on:''
    });

   
    useImperativeHandle(ref, () => ({
        openModal() {         
            setModalBox(true);
        },  
    }));

    const handleChange = (event) => {
        const { name, value } = event.target;
        // Update formData with the changed field value
        setFormData({
          ...formData,
          [name]: value, // Ensure the values are treated as numbers
        });
      };
   
    const handleSubmit = (e) => {
        e.preventDefault();    
        // Send a PATCH request to update the resource
        axios.post('https://mglps.in/api/academic/createSession.php', formData)
        .then((response) => {
          console.log('Session created successfully:', response.data);
          alert(response.data.message);
          setFormData({academic_year:'', opened_on:'', clossed_on:''})
          setModalBox(false)
          // You can add further actions after successful update
        })
        .catch((error) => {
          console.error('Error creating session:', error);
          // Handle errors here
        });
        };
    
    return(
        <>
            <Modal  onHide={setModalBox} show={modalBox} centered> 
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Academic Session</h5>
                        <button type="button" className="btn-close"  onClick={()=>setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                    <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12 col-sm-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Academic Session<span className="required">*</span></label>
                                        <input                                                    
                                            type="text" 
                                            className="form-control" 
                                            id="exampleFormControlInput1" 
                                            name="academic_year"
                                            value={formData.academic_year}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label text-primary">From Date<span className="required">*</span></label>
                                        <input                                                     
                                            type="date" 
                                            className="form-control" 
                                            id="exampleFormControlInput1"                                                     
                                            name="opened_on"
                                            value={formData.opened_on}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label text-primary">To Date<span className="required">*</span></label>
                                        <input                                                     
                                            type="date" 
                                            className="form-control" 
                                            id="exampleFormControlInput1"                                                     
                                            name="clossed_on"
                                            value={formData.clossed_on}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success light" onClick={handleSubmit}>Save</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>setModalBox(false)}>Close</button>
                    </div>
                </div>                
            </Modal>
        </>
    )
})

export default NewSessionModal
