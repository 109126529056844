import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {Modal} from 'react-bootstrap';
import { useSfb } from '../../../context/SfbContext';

const FeeCollectionModal = forwardRef((props, ref) => {

    // Students Fee Balance
    const { sfb } = useSfb(); 
    console.log(sfb);
    const [modalBox, setModalBox] = useState(false);
    const [detail, setDetail] = useState(null);
    const [flag, setFlag] = useState(0);

    // Create a new Date object
    const currentDate = new Date();
    // Get the current date components
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const day = currentDate.getDate();

    // Display the current date and time
    const todate = `${year}-${month}-${day}`;

    const [formData, setFormData]=useState({
        student_uid:'', net_amt:'', paying_amt:'', 
        balance_amt:'', pay_status:'Complete', app_date:todate
    });

    // type file for image 
    const [imgFile, setImgFile] = useState(null)

    // get fee structures 
    useImperativeHandle(ref, () => ({
        openModal(pay_id) {  
            let filteredData = [];
            if(sfb.length >0){
                filteredData = sfb.filter(person => person.pay_id === pay_id);
            }
            setDetail(filteredData[0]);
            setFormData({
                ...formData,
                student_uid: filteredData[0].suid,
                net_amt: filteredData[0].balance_amt
            });
            setImgFile(filteredData[0].s_photo);
            setModalBox(true)  ;     
        },  
    }));

    
    const handleChange = (e) => {
        const { name, value } = e.target;   
        setFormData({ ...formData, [name]: value });
    };

    let curr_balance = formData.net_amt;
    let minus_amt = formData.paying_amt;
    const balance_left = curr_balance - minus_amt;

    const updateFormData = () => {
        setFormData({
            ...formData,
            balance_amt:balance_left
        });
        setFlag(1);
    }   
    // console.log(formData);

    const closeModal = () =>{
        setFormData({
            student_uid:'', net_amt:'', paying_amt:'', balance_amt:'', pay_status:'Complete', app_date:todate
        })
        setImgFile('');
        setModalBox(false);     
    }

    useEffect(() => {
        if (flag === 1) {
          // Auto-click the button with the specified ID
          document.getElementById('submitBtn').click();
        }
      }, [flag]);

    const handleSubmit = async (event) => {
        event.preventDefault();           
        // Assuming you have an API endpoint to send the data to, use fetch or a library like axios
            try {   
                const response = await fetch('https://mglps.in/api/fees/makePayment.php', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
            });
        
            if (response.ok) {
                // Data successfully posted to the database
                // Optionally, you can reset the form fields here
                setFlag(0);
                alert('Payment is complete...');
                setFormData({
                    student_uid:'', net_amt:'', paying_amt:'', balance_amt:'', 
                    pay_status:'Complete', app_date:todate
                })
                setModalBox(false); 
            } else {
                console.error('Payment failed');
            }
            } catch (error) {
                console.error('Error:', error);
            }
      };

    return(
        <>
            <Modal  onHide={setModalBox} size='lg' show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Fee Collection</h5>
                        <button type="button" className="btn-close"  onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                    <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-4 d-block">
                                            <div>
                                                <label className="form-label text-primary">Photo<span className="required">*</span></label>
                                                <div className="avatar-upload">
                                                    <div className="avatar-preview">
                                                        <div id="imagePreview"> 
                                                        <img src={imgFile} alt="Student_photo" width={50}/>			
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <span className="p-4"></span>
                                            <div>
                                                <h5>{detail !== null ?detail.s_name : null}</h5>
                                            </div> 
                                            <div>
                                                <h5>{detail !== null ?detail.grade : null}</h5>
                                            </div> 
                                        </div>
                                        <div className="col-xl-9 col-lg-8">
                                            <div className="row">
                                                <div className="col-xl-12 col-sm-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label text-primary">UID NO<span className="required">*</span></label>
                                                        <input                                                     
                                                            type="text" 
                                                            className="form-control" 
                                                            id="exampleFormControlInput1"                                                     
                                                            name="student_uid"
                                                            value={formData.student_uid}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput5" className="form-label text-primary">Outstanding Fee<span className="required">*</span></label>
                                                        <input                                                    
                                                            type="text" 
                                                            className="form-control" 
                                                            id="exampleFormControlInput5" 
                                                            name="net_amt"
                                                            value={formData.net_amt}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="form-label text-primary">Paying Amount<span className="required">*</span></label>
                                                    <input                                                     
                                                        type="number" 
                                                        className="form-control" 
                                                        id="exampleFormControlInput1" 
                                                        name="paying_amt"
                                                        value={formData.paying_amt}
                                                        onChange={handleChange}
                                                    />	
                                                </div>
                                                <span className='p-2'></span>
                                                <div>
                                                    <label className="form-label text-primary">Balance after <span className="required">*</span></label>
                                                    <input                                                     
                                                        type="text" 
                                                        className="form-control" 
                                                        id="exampleFormControlInput1" 
                                                        value={balance_left}
                                                        readOnly
                                                    />	
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success light" onClick={updateFormData}>Save</button>
                        <button id="submitBtn" style={{display:"none"}} onClick={handleSubmit}>Submit</button>
                        <button type="button" className="btn btn-danger light" onClick={closeModal}>Close</button>
                    </div>
                </div>                
            </Modal>
        </>
    )
})

export default FeeCollectionModal
