export const MenuList = [
    //Dashboard
    {
        title: 'Dashboard',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="material-symbols-outlined">home</i>,
        content: [
            {
                title: 'Dashboard Light',
                to: 'dashboard',					
            },
            {
                title: 'Dashboard Dark',
                to: 'dashboard-dark',
            },
            {
                title: 'Finance',
                to: 'finance',
            },
           
        ],
    },
    {
        title: 'Admin Cell',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="material-symbols-outlined">receipt</i>,
        content: [
            {
                title: 'Manage Session',
                to: 'session-management',					
            },
            {
                title: 'Add Fee',
                to: 'new-fee',					
            },
            {
                title: 'Enrollment',
                to: 'enrollment',					
            },
            {
                title: 'Manage Fees',
                to: 'fee-management',
            },
            {
                title: 'Fee Collection',
                to: 'fees-collection',
            },
            {
                title: 'Subject Management',
                to: 'manage-subject',
            },
            // {
            //     title: 'Class Management',
            //     to: 'manage-class',
            // },
            // {
            //     title: 'Exam Routine',
            //     to: 'exam-management',
            // },
           
        ],
    },
    {
        title: 'Student',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="material-symbols-outlined">school</i>,
        content: [
            {
                title: 'Student',
                to: 'student',					
            },
            {
                title: 'Attendance',
                to: 'attendance',					
            },
            // {
            //     title: 'Student Detail',
            //     to: 'student-detail',
            // },
            // {
            //     title: 'Add New Student',
            //     to: 'add-student',
            // },
            {
                title: 'Assessment Mark',
                to: 'assessment-mark',
            },
           
        ],
    },
    {
        title:'Teacher (Disabled)',
        classsChange: 'mm-collapse',		
        iconStyle: <i className="material-symbols-outlined">person</i>,
        content: [
            // {
            //     title: 'Teacher',
            //     to: 'teacher',					
            // },
            // {
            //     title: 'Teacher Detail',
            //     to: 'teacher-detail',					
            // },
            // {
            //     title: 'Add New Teacher',
            //     to: 'add-teacher',					
            // },
        ],
    },
  
    {
        title: 'File Manager',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="material-icons">folder</i>	,
        content: [
            {
                title: 'File Manager',
                to: 'file-manager',					
            },
            // {
            //     title: 'User',
            //     to: 'user',
            // },
            // {
            //     title: 'Calendar',
            //     to: 'calendar',
            // },            
            // {
            //     title: 'Chat',
            //     to: 'chat',
            // },
            // {
            //     title: 'Activity',
            //     to: 'activity',
            // },
            {
                title: 'Uploader',
                to: 'uploader',
            },
        ],
    },        
    //Apps
    {
        title: 'Apps (Disabled)',	
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">app_registration </i>,
        content: [
            // {
            //     title: 'Profile',
            //     to: 'app-profile'
            // },
            // {
            //     title: 'Edit Profile',
            //     to: 'edit-profile'
            // },
            // {
            //     title: 'Post Details',
            //     to: 'post-details'
            // },
            // {
            //     title: 'Email',
            //     //to: './',
            //     hasMenu : true,
            //     content: [
            //         {
            //             title: 'Compose',
            //             to: 'email-compose',
            //         },
            //         {
            //             title: 'Index',
            //             to: 'email-inbox',
            //         },
            //         {
            //             title: 'Read',
            //             to: 'email-read',
            //         }
            //     ],
            // },
            // {
            //     title:'Calendar',
            //     to: 'app-calender'
            // },
            // {
            //     title: 'Envoices',
            //     //to: './',
            //     hasMenu : true,
            //     content: [
            //         {
            //             title: 'Checkout',
            //             to: 'ecom-checkout',
            //         },
            //         {
            //             title: 'Invoice',
            //             to: 'ecom-invoice',
            //         },
            //         {
            //             title: 'Customers',
            //             to: 'ecom-customers',
            //         },
            //     ],
            // },
        ],
    },
    //Charts
    {
        title: 'Charts (Disabled)',	
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">assessment</i>,
        content: [
            
            // {
            //     title: 'RechartJs',
            //     to: 'chart-rechart',					
            // },
            // {
            //     title: 'Chartjs',
            //     to: 'chart-chartjs',					
            // },
            // {
            //     title: 'Sparkline',
            //     to: 'chart-sparkline',					
            // },
            // {
            //     title: 'Apexchart',
            //     to: 'chart-apexchart',					
            // },
        ]
    },
    //Boosttrap
    
    //plugins
    
   
    //Forms
    
    //Table
    {
        title:'Table (Disabled)',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">table_chart</i>,
        content : [
            // {
            //     title:'Table Filtering',
            //     to: 'table-filtering',
            // },
            // {
            //     title:'Table Sorting',
            //     to: 'table-sorting',
            // },          
           

        ]
    },
    //Pages
    
    
]