import React, { useRef} from 'react';
import ViewFeesModal from '../Modals/ViewFeesModal';
import DeleteConfirmModal from '../Modals/DeleteConfirmModal';
import EditFeeModal from '../Modals/EditFeeModal';
import { useFees } from '../../../context/FeeContext';

const ManageFees = () => {

   // get fee structures 
    const { feesData, loading, refreshData } = useFees(); 
    // console.log(feesData);

    let slno = 1;

    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();	

    return (
          <>
              <div className="row">
                  <div className="col-xl-12">
                      <div className="card">
                          <div className="card-header">
                              <h5 className="mb-0">Fee(s) Management</h5>
                              <span className='p-2'></span>
                              <button className='btn btn-success' onClick={refreshData} disabled={loading}>
                                Refresh Data
                            </button>
                          </div>
                          {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <div>
                          {feesData.length > 0
                            ?
                          (<div className="card-body">
                              <div className="row">
                                <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                                  <div className="table-responsive full-data">
                                      <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                          <table className="table-responsive-lg table display dataTablesCard student-tab dataTable no-footer" fee_id="example-student">
                                              <thead>
                                                  <tr>
                                                      <th>Sl No</th>
                                                      <th>Class</th>
                                                      <th>Total ₹</th>
                                                      <th>Date</th>
                                                      <th>Action</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {feesData.map((item)=>(                                
                                                      <tr key={item.fee_id}>
                                                         
                                                          <td>
                                                              <div className="trans-list">
                                                                  <h4>{slno++}</h4>
                                                              </div>
                                                          </td>
                                                          <td>
                                                              <div className="date">{item.grade}</div>
                                                          </td>
                                                          <td><h6 className="mb-0">{item.total_fees}</h6></td>
                                                          <td><h6 className="mb-0">{item.added_on.slice(0,10)}</h6></td>
                                                          <td>
                                                              <div className="d-flex">
                                                                  <div className="icon-box icon-box-sm bg-light me-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef1.current.openModal(item.fee_id)} ><path d="M120-120v-320h80v184l504-504H520v-80h320v320h-80v-184L256-200h184v80H120Z"/></svg>
                                                                  </div>
                                                                  <div className="icon-box icon-box-sm bg-light me-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef2.current.openModal(item.fee_id)}><path d="M200-200h56l345-345-56-56-345 345v56Zm572-403L602-771l56-56q23-23 56.5-23t56.5 23l56 56q23 23 24 55.5T829-660l-57 57Zm-58 59L290-120H120v-170l424-424 170 170Zm-141-29-28-28 56 56-28-28Z"/></svg>
                                                                  </div>
                                                                  <div className="icon-box icon-box-sm bg-light">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef3.current.openModal(item.fee_id)} ><path d="M600-240v-80h160v80H600Zm0-320v-80h280v80H600Zm0 160v-80h240v80H600ZM120-640H80v-80h160v-60h160v60h160v80h-40v360q0 33-23.5 56.5T440-200H200q-33 0-56.5-23.5T120-280v-360Zm80 0v360h240v-360H200Zm0 0v360-360Z"/></svg>
                                                                  </div>
                                                              </div>
                                                          </td>
                                                         
                                                      </tr>
                                                  ))}
                                              </tbody>
                                          </table>    
                                          <div className="d-sm-flex text-center justify-content-between align-items-center">                           
                                              
                                              <div
                                                  className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                  id="example-student_wrapper"
                                              >
                                              </div>
                                          </div>   
                                      </div>
                                  </div>  
                              </div> 
                              </div>
                          </div>)
                            :(<div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s"> 
                                        <h3>No records found</h3>
                                    </div>
                                </div>
                            </div>
                            )
                            }
                            </div>
                            )}
                      </div>
                  </div>
              </div>  
              <ViewFeesModal ref={childRef1} />          
              <EditFeeModal ref={childRef2} />          
              <DeleteConfirmModal ref={childRef3} />          
          </>
    )
}

export default ManageFees
