// src/SubjectContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';


const SubjectContext = createContext();

export function SubjectProvider({ children }) {
  const [subList, setSubList] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const fetchSubjects = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://mglps.in/api/getAllSubjects.php');
      const data = await response.json();
      setSubList(data);
    } catch (error) {
      console.error('Error fetching fee data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  const refreshData = () => {
    fetchSubjects();
  };

  console.log(subList);

  return (
    <SubjectContext.Provider value={{ subList, loading, refreshData }}>
      {children}
    </SubjectContext.Provider>
  );
}

export function useSubjects() {
  const context = useContext(SubjectContext);
  if (context === undefined) {
    throw new Error('useSubjects must be used within a SubjectProvider');
  }
  return context;
}
