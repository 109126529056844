// src/AcademicContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';


const AcademicContext = createContext();

export function AcademicProvider({ children }) {
  const [academic, setAcademic] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const fetchAcademic = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://mglps.in/api/academic/getStatus.php');
      const data = await response.json();
      setAcademic(data);
    } catch (error) {
      console.error('Error fetching fee data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAcademic();
  }, []);

  const refreshData = () => {
    fetchAcademic();
  };

  console.log(academic);

  return (
    <AcademicContext.Provider value={{ academic, loading, refreshData }}>
      {children}
    </AcademicContext.Provider>
  );
}

export function useAcademic() {
  const context = useContext(AcademicContext);
  if (context === undefined) {
    throw new Error('useAcademic must be used within a AcademicProvider');
  }
  return context;
}
