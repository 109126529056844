import React, { useRef, useState, useEffect } from 'react'
import sprData from '../../../data/sprData';
import EditMarkModal from '../Modals/EditMarkModal';
import ViewMarkModal from '../Modals/ViewMarkModal';
import AddMarksModal from '../Modals/AddMarksModal';
import { useMarks } from '../../../context/CyamContext';
import { useGrade } from '../../../context/GradeContext';
import Form from 'react-bootstrap/Form';

const AssessmentMark = () => {

    let slno = 1;

    const { marks, loading, refreshData } = useMarks();
    const { grade } = useGrade();

    //Display selected result
    const [sclass, setSclass] = useState('Class');

    const [sg, setSg ]= useState('') //short grade code

    useEffect(()=>{
      if(sclass === 'Class-I'){
        setSg('C01');
      } else if(sclass === 'Class-II'){
        setSg('C02');
      } else if(sclass === 'Class-III'){
        setSg('C03');
      } else if(sclass === 'Class-IV'){
        setSg('C04');
      } else if(sclass === 'Class-V'){
        setSg('C05');
      } else {
        setSg('');
      }      
    }, [sclass]);


    const selectClassFuntion = (e) => {
      setSclass(e.target.value);
    }

    const childRef = useRef();
    const childRef1 = useRef();
    const childRef2 = useRef();

    
    // *************************************************************************
    // transformation
    const inputArray = marks;      
    const outputArray = [];    
    const studentMap = new Map();
    
    if(inputArray.length > 0){
      inputArray.forEach(item => {
        const { student_uid, am_id, subject_code, score, te, ac_year } = item;
        
        if (!studentMap.has(student_uid)) {
          studentMap.set(student_uid, { student_uid, subscoreList: [] });
        }
        
        const student = studentMap.get(student_uid);
        
        student.subscoreList.push({ am_id, subject_code, score, te, ac_year });
      });
    }
    
    studentMap.forEach(student => {
      outputArray.push(student);
    });
    
    let filteredGrade = [];

    if(sg !==''){
      filteredGrade = outputArray.filter(grade => grade.student_uid.match(/-([A-Z0-9]+)S/)?.[1] ===`${sg}`);
    } else {
      filteredGrade = outputArray;
    }

    return (
        <>   
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">All assessment marks (Internal/Hafl-Yearly/Annual Examination)</h5>
                              <div className='d-flex p-2'>                                
                                <button className='btn btn-success' onClick={refreshData}>
                                    Refresh Data
                                </button>
                                <span className="p-2"></span>
                                  <Form.Select size="sm" style={{maxWidth:'120px'}} value={sclass} onChange={selectClassFuntion}>                                    
                                    {grade.map((item) => <option value={item.class_options}>{item.class_label}</option>)}
                                  </Form.Select>
                                <span className="p-2"></span>
                                <button className='btn btn-success' onClick={() => childRef.current.openModal()}>
                                    Add Marks
                                </button>
                            </div>
                        </div>
                        
                        <div>
                          {filteredGrade.length > 0
                            ?
                          (<div className="card-body">
                              <div className="row">
                                <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                                  <div className="table-responsive full-data">
                                      <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                          <table className="table-responsive-lg table table-striped" id="example-student">
                                             <thead>
                                                    <tr> 
                                                        <th className='bg-danger'>#</th>
                                                        <th className='bg-danger'>Name/Roll No</th>
                                                        {/* {nwsarray.map((sub, index) => (
                                                        <th key={index}>{sub}</th>
                                                        ))} */}
                                                        <th className='bg-danger'>First Internal</th>
                                                        <th className='bg-danger'>Half-Yearly</th>
                                                        <th className='bg-danger'>Second Internal</th>
                                                        <th className='bg-danger'>Annual</th>
                                                        <th className='bg-danger'>Actions</th>
                                                    </tr>                
                                                </thead>
                                              <tbody>
                                                {filteredGrade.map((item, index) => 
                                                    <tr key={index}>
                                                      <td>
                                                          <div className="trans-list">
                                                                  <h4>{slno++}</h4>
                                                          </div>
                                                      </td>
                                                      <td>
                                                        {item.student_uid}
                                                      </td>
                                                      <td className='table-success'>
                                                        {item.subscoreList.filter(ass => ass.te ==='First Internal').map(subj =>
                                                          <h6>{subj.subject_code}-{subj.score === '0' ? 'AB':subj.score}</h6>
                                                          )}
                                                      </td>
                                                      <td className='table-warning'>
                                                      {item.subscoreList.filter(ass => ass.te ==='Half Yearly').map(subj =>
                                                          <h6>{subj.subject_code}-{subj.score === '0' ? 'AB':subj.score}</h6>
                                                          )}
                                                      </td>
                                                      <td className='table-info'>
                                                        {item.subscoreList.filter(ass => ass.te ==='Second Internal').map(subj =>
                                                          <h6>{subj.subject_code}-{subj.score === '0' ? 'AB':subj.score}</h6>
                                                          )}
                                                      </td>
                                                      <td className='table-danger'>
                                                      {item.subscoreList.filter(ass => ass.te ==='Annual').map(subj =>
                                                          <h6>{subj.subject_code}-{subj.score === '0' ? 'AB':subj.score}</h6>
                                                          )}
                                                      </td>
                                                      <td>
                                                          <div className="d-flex">
                                                              <div className="icon-box icon-box-sm bg-light me-2">
                                                              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef1.current.openModal(item.id)}><path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z"/></svg>
                                                              </div>
                                                              <div className="icon-box icon-box-sm bg-light me-2">
                                                              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef2.current.openModal(item.id)}><path d="M240-280h240v-80H240v80Zm120-160h240v-80H360v80Zm120-160h240v-80H480v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>
                                                              </div>
                                                          </div>
                                                      </td>
                                                    </tr>
                                                  )}
                                                  {/* {sprData.map((item, index)=>(                                
                                                      <tr key={index}>                                                         
                                                          <td>
                                                              <div className="trans-list">
                                                                  <h4>{slno++}</h4>
                                                              </div>
                                                          </td>
                                                          <td><h6 className="mb-0">{item.name}/{item.rollNo}</h6></td>
                                                           {item.subjectList.map(subj =>
                                                            <td className='text-center border border-b block lg:table-cell relative' style={{backgroundColor: parseInt(subj.score) >= 80 ? 'green' : 'red'}}>
                                                                <h6 style={{color: parseInt(subj.score) > 79 ? 'green' : (parseInt(subj.score) >= 59 ? 'Tomato': 'blue')}}>{subj.score == '0' ? 'AB':subj.score}</h6>
                                                            </td>
                                                            )}
                                                          <td>
                                                              <div className="d-flex">
                                                                  <div className="icon-box icon-box-sm bg-light me-2">
                                                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef1.current.openModal(item.id)}><path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z"/></svg>
                                                                  </div>
                                                                  <div className="icon-box icon-box-sm bg-light me-2">
                                                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef2.current.openModal(item.id)}><path d="M240-280h240v-80H240v80Zm120-160h240v-80H360v80Zm120-160h240v-80H480v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>
                                                                  </div>
                                                              </div>
                                                          </td>
                                                         
                                                      </tr>
                                                  ))} */}
                                              </tbody>
                                          </table>    
                                          <div className="d-sm-flex text-center justify-content-between align-items-center">                           
                                              
                                              <div
                                                  className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                  id="example-student_wrapper"
                                              >
                                              </div>
                                          </div>   
                                      </div>
                                  </div>  
                              </div> 
                              </div>
                          </div>)
                            :(<div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s"> 
                                        <h3>No records found</h3>
                                    </div>
                                </div>
                            </div>
                            )
                            }
                        </div>
                        {/* <div className="d-flex justify-content-center justify item-center p-4">
                              <button
                                  onClick={() => handlePageChange(currentPage - 1)}
                                  disabled={currentPage === 1}
                                  style={buttonStyle} // Add padding to the button
                                >
                                  Back
                                </button>
                                {Array.from({ length: totalPages }, (_, index) => (
                                  <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={currentPage === index + 1 ? 'active' : ''}
                                    style={buttonStyle} // Add padding to the button
                                  >
                                    {index + 1}
                                  </button>
                                ))}
                                <button
                                  onClick={() => handlePageChange(currentPage + 1)}
                                  disabled={currentPage === totalPages}
                                  style={buttonStyle} // Add padding to the button
                                >
                                  Next
                                </button>
                        </div> */}
                    </div>
                </div>
            </div>  
            <AddMarksModal ref={childRef} />
            <EditMarkModal ref={childRef1} />
            <ViewMarkModal ref={childRef2} />
        </>
    )
}

export default AssessmentMark
