// src/SbscContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';


const SbscContext = createContext();

export function SBSCProvider({ children }) {
  const [sbscData, setSbscData] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const fetchsbscData = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://mglps.in/api/sbscode/sbscode.php');
      const data = await response.json();
      setSbscData(data);
    } catch (error) {
      console.error('Error fetching fee data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchsbscData();
  }, []);

  const refreshData = () => {
    fetchsbscData();
  };

 
  return (
    <SbscContext.Provider value={{ sbscData, loading, refreshData }}>
      {children}
    </SbscContext.Provider>
  );
}

export function useSbscData() {
  const context = useContext(SbscContext);
  if (context === undefined) {
    throw new Error('useSbscData must be used within a SBSCProvider');
  }
  return context;
}
