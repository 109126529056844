import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {Modal} from 'react-bootstrap';
import { useDataContext } from '../../../context/DataContext';

const DeleteConfirmModal = forwardRef((props, ref) => {

    const { toggleRefetchFlag, toggleCrudFlag } = useDataContext();

    const [modalBox, setModalBox] = useState(false);
    const [dataId, setDataId] = useState('');

    useImperativeHandle(ref, () => ({
        openModal(fee_id) {
            console.log(fee_id);
            setDataId(fee_id);
            setModalBox(true);            
        },  
    }));


    const deleteData = async () => {
        try {
          // Define the API endpoint URL with dataId
          const apiUrl = `https://mglps.in/api/fees/removeFS.php`; // Adjust the URL as needed
    
           // Create the data payload as an object
            const payload = {
                fee_id: dataId,
            };

            // Make a DELETE request to the API with JSON payload using async/await
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                // Add any additional headers if needed (e.g., authorization token)
                },
                body: JSON.stringify(payload), // Convert the payload to JSON
            });
    
          if (!response.ok) {
            throw new Error(`Failed to delete data with ID ${dataId}`);
          }
    
          console.log(`Data with ID ${dataId} has been successfully deleted`);
          setModalBox(false); // Close the modal after successful delete
          // Handle the success here
        } catch (error) {
          console.error(`Error deleting data with ID ${dataId}: ${error.message}`);
          // Handle the error here
        }
        toggleRefetchFlag();
        toggleCrudFlag();
      };

        
      
    
    return(
        <>
            <Modal  onHide={setModalBox} show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                        <button type="button" className="btn-close"  onClick={()=>setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="card-body">
                            <h1>Are you sure!</h1>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger light" onClick={deleteData} >Delete</button>
                        <button type="button" className="btn btn-success light" onClick={()=>setModalBox(false)}>Cancel</button>
                    </div>
                </div>                
            </Modal>
        </>
    )
})


export default DeleteConfirmModal
