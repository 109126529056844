import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {Modal} from 'react-bootstrap';
import axios from 'axios';

const AddSubjectModal = forwardRef((props, ref) => {

    const [modalBox, setModalBox] = useState(false);
    const [formData, setFormData] = useState({});


    useImperativeHandle(ref, () => ({
        openModal() {
            setModalBox(true);            
        },  
    }));

    const handleChange = (event) => {
        const { value } = event.target;
        // Update formData with the changed field value
        setFormData({
        });
      }; 

    console.log(formData);
    const handleSubmit = (e) => {
        e.preventDefault();    
        // Send a PATCH request to update the resource
        axios.patch('https://mglps.in/api/academic/editSession.php', formData)
        .then((response) => {
          console.log('status update successful:', response.data);
          alert(response.data.message);
          setModalBox(false)
          // You can add further actions after successful update
        })
        .catch((error) => {
          console.error('Error updating data:', error);
          // Handle errors here
        });
        };
  return (
    <>
            <Modal  onHide={setModalBox} show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add New Subject</h5>
                        <button type="button" className="btn-close"  onClick={()=>setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12 col-lg-4 d-block">
                                    <div className="mb-3">
                                        <h1>Add Subject</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success light" onClick={handleSubmit}>Save</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>setModalBox(false)}>Close</button>
                    </div>
                </div>                
            </Modal>
        </>
  )
})

export default AddSubjectModal
