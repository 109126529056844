import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {Modal} from 'react-bootstrap';
import { useFees } from '../../../context/FeeContext';
import axios from 'axios';

const EditFeeModal = forwardRef((props, ref) => {
    const { feesData } = useFees(); 
    const [modalBox, setModalBox] = useState(false);
    const [formData, setFormData] = useState({});

    //flags
    const [canSave, setCanSave ] = useState(false);

    useImperativeHandle(ref, () => ({
        openModal(fee_id) {
            const filteredData = feesData.filter(selected => selected.fee_id == `${fee_id}`);
            setFormData({
                fee_id:fee_id,
                grade:filteredData[0].grade,
                admission_fee: filteredData[0].admission_fee,
                tuition_fee:filteredData[0].tuition_fee,
                transport_fee:filteredData[0].transport_fee,
                sport_fee: filteredData[0].sport_fee,
                library_fee: filteredData[0].library_fee,
                idcard_fee: filteredData[0].idcard_fee,
                computer_lab_fee: filteredData[0].computer_lab_fee,
                exam_fee: filteredData[0].exam_fee,
                marksheet_fee: filteredData[0].marksheet_fee,
                miscellaneous_fee: filteredData[0].miscellaneous_fee,
                record_maintenance_fee: filteredData[0].record_maintenance_fee,
                tie_belt_fee: filteredData[0].tie_belt_fee,
                extra_curicular: filteredData[0].extra_curicular,
                total_fees: filteredData[0].total_fees
            });
            setModalBox(true);            
        },  
    }));

    const handleChange = (event) => {
        const { name, value } = event.target;
        // Update formData with the changed field value
        setFormData({
          ...formData,
          [name]: value, // Ensure the values are treated as numbers
        });
      }; 
              
    const arr = [parseInt(formData.admission_fee), parseInt(formData.tuition_fee)*12, parseInt(formData.transport_fee),
        parseInt(formData.sport_fee), parseInt(formData.library_fee), parseInt(formData.idcard_fee), parseInt(formData.computer_lab_fee),
        parseInt(formData.exam_fee), parseInt(formData.marksheet_fee), parseInt(formData.miscellaneous_fee), parseInt(formData.record_maintenance_fee)*12, 
        parseInt(formData.tie_belt_fee), parseInt(formData.extra_curicular)];
        
    const netAmount = arr.reduce((prev, cur) => prev + cur); //Total value of fees

    const updateTotalFees = () => {
        setFormData({
            ...formData,
            total_fees: netAmount,
        });
        setCanSave(true);
    };

   
    const handleSubmit = (e) => {
        e.preventDefault();    
        // Send a PATCH request to update the resource
        axios.patch('https://mglps.in/api/fees/update.php', formData)
        .then((response) => {
          console.log('Fees updated successfully:', response.data);
          alert(response.data.message);
          setModalBox(false)
          // You can add further actions after successful update
        })
        .catch((error) => {
          console.error('Error updating data:', error);
          // Handle errors here
        });
        };
    
    return(
        <>
            <Modal  onHide={setModalBox} size="xl" show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Edit Fee Structure</h5>
                        <button type="button" className="btn-close"  onClick={()=>setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                    <div className="card-body">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 d-block">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput4" className="form-label txt-primary">Class<span className="required">*</span></label>
                                        <select className="form-control" id="exampleFormControlInput4" >
                                            <option>{formData.grade}</option>                                            
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <h1>Total Amount ₹:{formData.total_fees}</h1>
                                    </div>
                                    <div className="mb-3">
                                        <button className='btn btn-primary' onClick={updateTotalFees}>Update Total</button>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Admission Fee<span className="required">*</span></label>
                                                <input                                                    
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput1" 
                                                    name="admission_fee"
                                                    value={formData.admission_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Monthly Tution Fee (Auto multiplied x12 months)<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput1"                                                     
                                                    name="tuition_fee"
                                                    value={formData.tuition_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Transportation Fee<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="transport_fee"
                                                    value={formData.transport_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Game & Sport Fee<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3" 
                                                    name="sport_fee"
                                                    value={formData.sport_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Examination Fee<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="exam_fee"
                                                    value={formData.exam_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Extra Curicular Activities<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="extra_curicular"
                                                    value={formData.extra_curicular}
                                                    onChange={handleChange}
                                                    required/>
                                                    
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Miscellaneous Fee<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="miscellaneous_fee"
                                                    value={formData.miscellaneous_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Accessories (Tie, Belt & Shocks)<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                    
                                                    name="tie_belt_fee"
                                                    value={formData.tie_belt_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput5" className="form-label text-primary">Library Fee (Refundable)<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput5" 
                                                    name="library_fee"
                                                    value={formData.library_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Identification Card<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="idcard_fee"
                                                    value={formData.idcard_fee}
                                                    onChange={handleChange}
                                                    required/>
                                                    
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Computer Lab<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                    
                                                    name="computer_lab_fee"
                                                    value={formData.computer_lab_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Marksheet<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="marksheet_fee"
                                                    value={formData.marksheet_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Record Maintainance (Auto multiplied x12 months)<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="record_maintenance_fee"
                                                    value={formData.record_maintenance_fee}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Net Amount in ₹:<span className="required">*</span></label>
                                                <input                                                     
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="total_fees"
                                                    value={formData.total_fees}
                                                    readOnly/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success light" onClick={handleSubmit} disabled={canSave === false}>Save</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>setModalBox(false)}>Close</button>
                    </div>
                </div>                
            </Modal>
        </>
    )
})

export default EditFeeModal
