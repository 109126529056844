import React, { useState, useRef } from 'react';
import { useSfb } from '../../../context/SfbContext';
import PaymentActivitiesModal from '../Modals/PaymentActivitiesModal';
import FeeCollectionModal from '../Modals/FeeCollectionModal';
import Form from 'react-bootstrap/Form';
import { useGrade } from '../../../context/GradeContext';

const itemsPerPage = 10;


const FeeCollection = () => {

    const childRef1 = useRef();
    const childRef2 = useRef();

    //Grade
    const { grade } = useGrade();

    const { sfb, loading, refreshData } = useSfb();
    
    console.log(sfb);
    // Create an object to store the entries grouped by suid
    const groupedData = {};

    // Iterate through the payData array
   if(sfb.length > 0){
    for (const entry of sfb) {
      const suid = entry.suid;
      const app_date = new Date(entry.app_date);

      if (!groupedData[suid]) {
        groupedData[suid] = [entry];
      } else {
        groupedData[suid].push(entry);
      }
    }
   }

    // Create a new array to store the data with more than one pay_id filtered by app_date
    const newData = [];

    // Iterate through the grouped data
    for (const suid in groupedData) {
      if (groupedData[suid].length > 1) {
        // Sort entries by app_date in descending order
        groupedData[suid].sort((a, b) => new Date(b.app_date) - new Date(a.app_date));
        newData.push(groupedData[suid][0]); // Push the entry with the latest app_date
      }
    }

    // Create an object to count the number of pay_ids for each suid
    const payIdCounts = {};

    // Iterate through the payData array to count pay_ids
    if(sfb.length > 0){
      for (const entry of sfb) {
        const suid = entry.suid;
  
        if (payIdCounts.hasOwnProperty(suid)) {
          payIdCounts[suid]++;
        } else {
          payIdCounts[suid] = 1;
        }
      }
    }

    // Filter the payData array to include only entries with suid that has one pay_id
    let filteredPayData = [];
    if(sfb.length > 0){
      filteredPayData = sfb.filter((entry) => payIdCounts[entry.suid] === 1);
    }
    // const filteredPayData = sfb.filter((entry) => payIdCounts[entry.suid] === 1);

    // combine two array 
    const combinedArray = [...newData, ...filteredPayData];

    let slno = 1; 

    //For Class or Grade Selection
    const [selected, setSelected] = useState('all');

    const selectOption = (e) => {
        setSelected(e.target.value);
    }

    let selectedData = combinedArray;
    if(selected !=='all'){
        selectedData = combinedArray.filter(item => item.grade === `${selected}`);
    }

    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the index range for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Get the data to display for the current page
    const currentData = selectedData.slice(startIndex, endIndex);

    // Calculate the total number of pages
    const totalPages = Math.ceil(selectedData.length / itemsPerPage);

    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };

      const buttonStyle = {
        padding: '8px 16px',
        borderRadius: '8px', // Add rounded corners
      };

     
        
      
      return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Fee(s) Collection List</h5>
                              <div className='d-flex'>  
                                <Form.Select size="sm" style={{maxWidth:'120px'}} value={selected} onChange={selectOption}>
                                    {grade.map((item) => <option value={item.class_options}>{item.class_label}</option>)}
                                </Form.Select>
                                <span className="p-2"></span>                               
                                <button className='btn btn-success' onClick={refreshData} disabled={loading}>
                                    Refresh Data
                                </button>
                            </div>
                        </div>
                        
                        <div>
                          {currentData.length > 0
                            ?
                          (<div className="card-body">
                              <div className="row">
                                <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                                  <div className="table-responsive full-data">
                                      <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                          <table className="table-responsive-lg table display dataTablesCard student-tab dataTable no-footer" id="example-student">
                                              <thead>
                                                  <tr>
                                                      <th>Sl No</th>
                                                      <th>ID NO</th>
                                                      <th>Name</th>
                                                      <th>Class</th>
                                                      <th>Last Payment Date</th>
                                                      <th>Balance Amt. ₹</th>
                                                      <th>Action</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {currentData.map((item)=>(                                
                                                      <tr key={item.pay_id}>                                                         
                                                          <td>
                                                              <div className="trans-list">
                                                                  <h4>{slno++}</h4>
                                                              </div>
                                                          </td>
                                                          <td>
                                                              <div className="date">{item.suid}</div>
                                                          </td>
                                                          <td><h6 className="mb-0">{item.s_name}</h6></td>
                                                          <td><h6 className="mb-0">{item.grade}</h6></td>
                                                          <td><h6 className="mb-0">{item.app_date}</h6></td>
                                                          <td><h6 className="mb-0">{item.balance_amt}</h6></td>
                                                          <td>
                                                              <div className="d-flex">
                                                                  <div className="icon-box icon-box-sm bg-light me-2">
                                                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef1.current.openModal(item.pay_id)}><path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z"/></svg>
                                                                  </div>
                                                                  <div className="icon-box icon-box-sm bg-light me-2">
                                                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => childRef2.current.openModal(item.suid)}><path d="M240-280h240v-80H240v80Zm120-160h240v-80H360v80Zm120-160h240v-80H480v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>
                                                                  </div>
                                                              </div>
                                                          </td>
                                                         
                                                      </tr>
                                                  ))}
                                              </tbody>
                                          </table>    
                                          <div className="d-sm-flex text-center justify-content-between align-items-center">                           
                                              
                                              <div
                                                  className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                  id="example-student_wrapper"
                                              >
                                              </div>
                                          </div>   
                                      </div>
                                  </div>  
                              </div> 
                              </div>
                          </div>)
                            :(<div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s"> 
                                        <h3>No records found</h3>
                                    </div>
                                </div>
                            </div>
                            )
                            }
                        </div>
                        <div className="d-flex justify-content-center justify item-center p-4">
                              <button
                                  onClick={() => handlePageChange(currentPage - 1)}
                                  disabled={currentPage === 1}
                                  style={buttonStyle} // Add padding to the button
                                >
                                  Back
                                </button>
                                {Array.from({ length: totalPages }, (_, index) => (
                                  <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={currentPage === index + 1 ? 'active' : ''}
                                    style={buttonStyle} // Add padding to the button
                                  >
                                    {index + 1}
                                  </button>
                                ))}
                                <button
                                  onClick={() => handlePageChange(currentPage + 1)}
                                  disabled={currentPage === totalPages}
                                  style={buttonStyle} // Add padding to the button
                                >
                                  Next
                                </button>
                        </div>
                    </div>
                </div>
            </div>  
            <FeeCollectionModal ref={childRef1} />          
            <PaymentActivitiesModal ref={childRef2} />           
        </>
      )
}

export default FeeCollection;
