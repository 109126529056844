import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {Modal} from 'react-bootstrap';
import { useFees } from '../../../context/FeeContext';

const ViewFeesModal = forwardRef((props, ref) => {
    const { feesData  } = useFees(); 
    const [modalBox, setModalBox] = useState(false);
    const [itemObj, setItemObj] = useState([]);

    // get fee structures 
    console.log(feesData);
    useImperativeHandle(ref, () => ({
        openModal(fee_id) {
            const filteredItem = feesData.filter(selected => selected.fee_id == `${fee_id}`);
            setItemObj(filteredItem);
            setModalBox(true);            
        },  
    }));
    
    return(
        <>
            <Modal  onHide={setModalBox} size="xl" show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">View Fee Details</h5>
                        <button type="button" className="btn-close"  onClick={()=>setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                    <div className="card-body">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 d-block">
                                <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput4" className="form-label text-primary">Class<span className="required">*</span></label>
                                        <select className="form-control" id="exampleFormControlInput4" disabled>
                                            <option>{itemObj.length >0 ?itemObj[0].grade : ''}</option>                                            
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <h1>Total Amount ₹:{itemObj.length >0 ?itemObj[0].total_fees : ''}</h1>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Admission Fee<span className="readOnly">*</span></label>
                                                <input 
                                                    disabled
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput1" 
                                                    name="admission_fee"
                                                    value={itemObj.length >0 ?itemObj[0].admission_fee: ''}
                                                    readOnly/>
                                            </div>
                                            <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Monthly Tution Fee (Auto multiplied x12 months)<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput1"                                                     
                                                    name="tuition_fee"
                                                    value={itemObj.length >0 ?itemObj[0].tuition_fee: ''}
                                                    readOnly/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Transportation Fee<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="transport_fee"
                                                    value={itemObj.length >0 ?itemObj[0].transport_fee: ''}
                                                    readOnly/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Game & Sport Fee<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3" 
                                                    name="sport_fee"
                                                    value={itemObj.length >0 ?itemObj[0].sport_fee: ''}
                                                    readOnly/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Examination Fee<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="exam_fee"
                                                    value={itemObj.length >0 ?itemObj[0].exam_fee: ''}
                                                    readOnly/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Extra Curicular Activities<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="extra_curicular"
                                                    value={itemObj.length >0 ?itemObj[0].extra_curicular: ''}
                                                    readOnly/>
                                                    
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Miscellaneous Fee<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                     
                                                    name="miscellaneous_fee"
                                                    value={itemObj.length >0 ?itemObj[0].miscellaneous_fee: ''}
                                                    readOnly/>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Accessories (Tie, Belt & Shocks)<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput3"                                                    
                                                    name="tie_belt_fee"
                                                    value={itemObj.length >0 ?itemObj[0].tie_belt_fee: ''}
                                                    readOnly/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput5" className="form-label text-primary">Library Fee (Refundable)<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput5" 
                                                    name="library_fee"
                                                    value={itemObj.length >0 ?itemObj[0].library_fee: ''}
                                                    readOnly/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Identification Card<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="idcard_fee"
                                                    value={itemObj.length >0 ?itemObj[0].idcard_fee: ''}
                                                    readOnly/>
                                                    
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Computer Lab<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                    
                                                    name="computer_lab_fee"
                                                    value={itemObj.length >0 ?itemObj[0].computer_lab_fee: ''}
                                                    readOnly/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Marksheet<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="marksheet_fee"
                                                    value={itemObj.length >0 ?itemObj[0].marksheet_fee: ''}
                                                    readOnly/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Record Maintainance (Auto multiplied x12 months)<span className="readOnly">*</span></label>
                                                <input
                                                    disabled 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleFormControlInput6"                                                     
                                                    name="record_maintenance_fee"
                                                    value={itemObj.length >0 ?itemObj[0].record_maintenance_fee: ''}
                                                    readOnly/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger light" onClick={()=>setModalBox(false)}>Close</button>
                    </div>
                </div>                
            </Modal>
        </>
    )
})

export default ViewFeesModal;
