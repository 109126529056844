import React from 'react'

const ExamRoutine = () => {
  return (
    <div className='d-flex justify-content-center align-item-center'>
      <h1>Create and manage Exam Routine </h1>
    </div>
  )
}

export default ExamRoutine
