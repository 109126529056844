import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {Modal} from 'react-bootstrap';
import { useAcademic } from '../../../context/AcademicContext';
import axios from 'axios';

const EditAcademicModal = forwardRef((props, ref) => {
    const { academic, loading, refreshData } = useAcademic(); 
    const [modalBox, setModalBox] = useState(false);
    const [formData, setFormData] = useState({});


    useImperativeHandle(ref, () => ({
        openModal(ss_id) {
            const filteredData = academic.filter(selected => selected.ss_id == `${ss_id}`);
            setFormData({
                ss_id:ss_id,
                sess_status:filteredData[0].sess_status
            });
            setModalBox(true);            
        },  
    }));

    const handleChange = (event) => {
        const { value } = event.target;
        // Update formData with the changed field value
        setFormData({
          ...formData,
          sess_status: value, // Ensure the values are treated as numbers
        });
      }; 

    console.log(formData);
    const handleSubmit = (e) => {
        e.preventDefault();    
        // Send a PATCH request to update the resource
        axios.patch('https://mglps.in/api/academic/editSession.php', formData)
        .then((response) => {
          console.log('status update successful:', response.data);
          alert(response.data.message);
          setModalBox(false)
          // You can add further actions after successful update
        })
        .catch((error) => {
          console.error('Error updating data:', error);
          // Handle errors here
        });
        };
    
    return(
        <>
            <Modal  onHide={setModalBox} show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Change Academic Session Status</h5>
                        <button type="button" className="btn-close"  onClick={()=>setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12 col-lg-4 d-block">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput4" className="form-label txt-primary">Status<span className="required">*</span></label>
                                            <select className="form-control" id="exampleFormControlInput4" onChange={handleChange} value={formData.sess_status}>
                                            <option>{formData.sess_status}</option>                                            
                                            <option value="closed">Closed</option>                                            
                                            <option value="active">Active</option>                                            
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success light" onClick={handleSubmit}>Save</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>setModalBox(false)}>Close</button>
                    </div>
                </div>                
            </Modal>
        </>
    )
})


export default EditAcademicModal
