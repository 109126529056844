import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {Modal} from 'react-bootstrap';
import axios from 'axios';

const DeleteSubjectModal = forwardRef((props, ref) => {

    const [modalBox, setModalBox] = useState(false);
    const [formData, setFormData] = useState({});


    useImperativeHandle(ref, () => ({
        openModal(subject_id) {
            setFormData({...formData, subject_id})
            setModalBox(true);            
        },  
    }));

    console.log(formData);
    const handleSubmit = (e) => {
        e.preventDefault();  
        alert('Please wait..., this is work in progress');  
        // Send a PATCH request to update the resource
        axios.patch('http://your/API/location', formData)
        .then((response) => {
          console.log('status update successful:', response.data);
          alert(response.data.message);
          setModalBox(false)
          // You can add further actions after successful update
        })
        .catch((error) => {
          console.error('Error updating data:', error);
          // Handle errors here
        });
        };
  return (
    <>
            <Modal  onHide={setModalBox} show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Removal cannot be undone, please make sure!</h5>
                        <button type="button" className="btn-close"  onClick={()=>setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12 col-lg-4 d-block">
                                    <div className="mb-3">
                                        <h1>Are you sure :{formData.subject_id}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success light" onClick={handleSubmit}>Yes remove</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>setModalBox(false)}>Close</button>
                    </div>
                </div>                
            </Modal>
        </>
  )
})

export default DeleteSubjectModal
