import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import  DatePicker  from "react-datepicker";
import { IMAGES } from '../Dashboard/Content';
import axios from 'axios';



const AddNewStudent = () => {

    // Get Classes API 
    const GET_GRADES = 'https://mglps.in/grades/getAllGrades.php';
    
    const [grades, setGrades] = useState([]);

    useEffect(() =>{
        const fetchClasses = async() =>{
            const res = await axios.get(GET_GRADES);
            setGrades(res.data);
          }
          fetchClasses();
    },[])

    console.log(grades);

    const initialFormData = {
        full_name: '',
        dob: '',
        pass_photo: null,
        class_admitted: '',
        roll_no: '',
        sex: '',
        email: '',
        admission_status: 'complete',
        guardian_name: '',
        contact_no: '',
        r_address: '',
        aadhar_no: '',
      };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
    
        if (type === 'file') {
          // Convert the selected file to Base64
          const file = e.target.files[0];
          const reader = new FileReader();
          reader.onloadend = () => {
            setFormData({ ...formData, [name]: reader.result });
          };
          if (file) {
            reader.readAsDataURL(file);
          }
        } else {
          setFormData({ ...formData, [name]: value });
        }
      };

    const [file, setFile] = useState(null)
    const fileHandler = (e) => {       
        setFile(e.target.files[0]);		
    }
    const RemoveFile = () =>{
        setFile(null)
    }

    const [startDate, setStartDate] = useState(new Date());	
	const onChange = (date) => {		
		setStartDate(date);		
	};
    
    console.log(formData);
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Student Details</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 d-block">
                                    <div>
                                        <label className="form-label text-primary">Photo<span className="required">*</span></label>
                                        <div className="avatar-upload">
                                            <div className="avatar-preview">
                                                <div id="imagePreview"                                                 
                                                    style={{backgroundImage: file ? "url(" + URL.createObjectURL(file) + ")" : "url(" + IMAGES.noimage +")" }}
                                                > 			
                                                </div>
                                            </div>
                                            <div className="change-btn mt-2 mb-lg-0 mb-3">
                                                <input type='file' className="form-control d-none"  onChange={fileHandler}  id="imageUpload" accept=".png, .jpg, .jpeg" />
                                                <label htmlFor="imageUpload" className="dlab-upload mb-0 btn btn-primary btn-sm">Choose File</label>
                                                <Link to={"#"} className="btn btn-danger light remove-img ms-2 btn-sm" onClick={RemoveFile}>Remove</Link>
                                            </div>
                                        </div>	
                                    </div>
                                    <span className='p-4'></span>
                                    <div className="flex flex-wrap mx-3 mb-1">
                                        <div className="file-input w-full px-9 mb-1 md:mb-0">
                                        <label className="form-label text-primary" htmlFor="grid-first-name">
                                            Gender
                                        </label>
                                        <div className='flex'>
                                            <label className="flex form-label text-primary p-2">
                                            <input
                                                className='p-4'
                                                type="checkbox"
                                                name="sex"
                                                value="male"
                                                checked={formData.sex === 'male'}
                                                onChange={handleChange}
                                            />
                                            <span className='p-2'>Male</span>
                                            </label>
                                            <span className="p-2"></span>
                                            <label className="flex form-label text-primary p-2">
                                            <input
                                                className='p-4'
                                                type="checkbox"
                                                name="sex"
                                                value="female"
                                                checked={formData.sex === 'female'}
                                                onChange={handleChange}
                                            />
                                            <span className='p-2'>Female</span>
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Full Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="James" />
                                            </div>
                                            <div className="mb-3">
                                                <label  className="form-label text-primary">Date & Place of Birth<span className="required">*</span></label>
                                                <div className="d-flex">                                                    
                                                    <DatePicker 
                                                        className="form-control"
                                                        selected={startDate}
								                        onChange={onChange}	
                                                    />
                                                    <input type="text" className="form-control w-50 ms-3" id="exampleFormControlInput7" placeholder="USA" />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Email<span className="required">*</span></label>
                                                <input type="email" className="form-control" id="exampleFormControlInput3" placeholder="hello@example.com" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlTextarea1" className="form-label text-primary">Address<span className="required">*</span></label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput4" className="form-label text-primary">Class<span className="required">*</span></label>
                                                {/* <input type="text" className="form-control" id="exampleFormControlInput4" placeholder="Wally" /> */}
                                                <select value={formData.class_admitted} onChange={handleChange} className="form-control" id="exampleFormControlInput4">
                                                    {/* <option value="">--Class--</option> */}
                                                    {grades.map((item) => <option value={item.class_options}>{item.class_label}</option>)}
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput5" className="form-label text-primary">Guardian Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleFormControlInput5" placeholder="Mana William" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Phone Number<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput6" placeholder="+123456789" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <button className="btn btn-outline-primary me-3">Save as Draft</button>
                                <button className="btn btn-primary" type="button">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default AddNewStudent;