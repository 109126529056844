import React, { useEffect, useState } from 'react';
import '../../../css/spinner.css'; // Import your CSS file for styling

const Spinner = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000); // Minimum spinning time of 1.5 seconds

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return visible ? (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  ) : null;
};

export default Spinner;

