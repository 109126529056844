import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {Modal} from 'react-bootstrap';
import axios from 'axios';
import FormUtility from '../Student/FormUtility';

const AttendanceModal = forwardRef((props, ref) => {

    const [modalBox, setModalBox] = useState(false);
    const [formData, setFormData] = useState({});
    const [canSave, setCanSave] = useState(false);

   

    useImperativeHandle(ref, () => ({
        openModal() {
            setModalBox(true);            
        },  
    }));

    console.log(formData);
    
    const handleSubmit = (e) => {
        e.preventDefault(); 
        formData.forEach(data => {
            axios.post('https://mglps.in/api/students/newAttendance.php', data)
                .then(response => {
                console.log('Attendance added successfully', response);
                // Handle the response here if needed
                })
                .catch(error => {
                console.error('Error making POST request', error);
                // Handle the error here
                });
            });
            setCanSave(false);
            setFormData('')
    };

    const handleClose = (e) => {
        e.preventDefault();    
        setCanSave(false);
        setModalBox(false);
    };

  return (
    <>
            <Modal  onHide={setModalBox} size='xl' show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Attendance data scanner</h5>
                        <button type="button" className="btn-close"  onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12 col-lg-4 d-block">
                                    <div className="mb-3">
                                        <FormUtility 
                                            setFormData={setFormData} 
                                            setCanSave={setCanSave}
                                            canSave={canSave}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success light" onClick={handleSubmit} disabled={canSave === false}>Save</button>
                        <button type="button" className="btn btn-danger light" onClick={handleClose}>Close</button>
                    </div>
                </div>                
            </Modal>            
        </>
  )
})


export default AttendanceModal
