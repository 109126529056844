// src/CyamContext.js CYAM-> Current Year Academic Marks
import React, { createContext, useContext, useState, useEffect } from 'react';


const CyamContext = createContext();

export function CyamProvider({ children }) {
  const [marks, setMarks] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const fetchMarks = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://mglps.in/api/marks/getMarks.php');
      const data = await response.json();
      setMarks(data);
    } catch (error) {
      console.error('Error fetching assessment marks:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMarks();
  }, []);

  const refreshData = () => {
    fetchMarks();
  };

  console.log(marks);

  return (
    <CyamContext.Provider value={{ marks, loading, refreshData }}>
      {children}
    </CyamContext.Provider>
  );
}

export function useMarks() {
  const context = useContext(CyamContext);
  if (context === undefined) {
    throw new Error('useMarks must be used within a CyamProvider');
  }
  return context;
}
