import React, { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {Modal} from 'react-bootstrap';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSbscData } from '../../../context/SbscContext';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSpinner } from '../../../context/SpinnerContext';
import Spinner from '../../components/Spinner/Spinner';
import Swal from 'sweetalert2';
import { useMarks } from '../../../context/CyamContext';


const AddMarkModal = forwardRef((props, ref) => {

    const { isLoading, showSpinner, hideSpinner } = useSpinner();
    const { marks, refreshData } = useMarks(); 

     useImperativeHandle(ref, () => ({
        openModal() {
            setModalBox(true);            
        },  
    }));

    const buttonRef = useRef(null);

    const currentDate = new Date();
    const currYear = currentDate.getFullYear();

    const [confirmSave, setConfirmSave] = useState('No');//Confirm to save

    const [modalBox, setModalBox] = useState(false);
    const [formDataArr, setFormDataArr] = useState([]);
    const [formData, setFormData] = useState({
            student_uid:'', student:'', subject:'', subject_code:'', score:'', te:'', ac_year:''
        });

    //get student UID on click
    const [uid, setUid] = useState(null);
    const [student, setStudent] = useState(null);
    const [subject, setSubject] = useState(null);

    //subject list with code
    const { sbscData } = useSbscData();
    const [subcode, setSubcode ]=useState('');
    const [msg, setMsg] = useState(null);

    //get assessment 
    const [assessment, setAssessment] = useState([]); //Assessment List
    const [selectedAssessment, setSelectedAssessment] = useState('');

    useEffect(() =>{
        const fetchAssessment = async() =>{
            const res = await axios.get('https://mglps.in/api/assessment/getAssessments.php');
            setAssessment(res.data);
          }
          fetchAssessment();
    },[])    

    const onCloseModalClicked = ()=>{
        setFormData({});
        setFormDataArr([]);
        setSelectedAssessment('');
        setSubcode('');
        setModalBox(false);
        setMsg('');

    }

    const selectOption = (e) => {
        const teValue = e.target.value;
        setSelectedAssessment(teValue);
    }

    // Auto check 
    useEffect(()=>{
        let exists = [];
        if(selectedAssessment !== '' && subcode !== ''){
            if(marks.length > 0){
                exists = marks.some((item) => item.te === `${selectedAssessment}` && item.subject_code === `${subcode}` && item.ac_year === `${currYear}`); 
            }
            if(exists === true){
                // setMsg(`${selectedAssessment} assessment marks already exists for ${subcode} of current year i.e. ${currYear}`);
                setMsg(`Assessment marks already exists for Code:${subcode}`);
            }else{
                setMsg('');
            }
        }
        
    },[selectedAssessment, subcode, marks])

    const handleSubcode = (e) => {
        setSubcode(e.target.value.toUpperCase());
    }

    let selectedClass = [];
    if(sbscData.length > 0){
        selectedClass = sbscData.filter(cde => cde.code ===`${subcode}`);
    }    
   

    const handleChange = (event) => {
        const { value } = event.target;
        // Update formData with the changed field value
        setFormData({
                student_uid:uid, 
                student:student, 
                subject:subject, 
                subject_code:subcode, 
                score:value, 
                te:selectedAssessment,
                ac_year:currYear
            });
      }; 

    const onInputClicked = (suid) => {
        let result = selectedClass.find(item => item.suid == suid);
        let obj = formData;
        if(Object.keys(obj).length > 0){
            setFormDataArr([...formDataArr, {...obj}]);
        } 
        setUid(suid);
        setStudent(result.student);
        setSubject(result.subjects);
    }

    // Create an object to store the last entered objects based on suid
    const lastEnteredObjects = {};

    // Loop through the array and update the last entered object for each suid
    formDataArr.forEach((obj) => {
    const { student_uid, student, subject, subject_code, score, te_name, ac_year } = obj;
        if (subject_code !== "" || score !== "" || student_uid !== "" || student !== "" || subject !=="" || te_name !== "" || ac_year !== "") {
            lastEnteredObjects[student_uid] = obj;
        }
    });

    // Convert the lastEnteredObjects object back to an array
    const filteredArray = Object.values(lastEnteredObjects);
    
    // double filter 
    // Create a new array that excludes objects with empty properties
    let finalFormDataArray = filteredArray.filter(item => {
        return Object.values(item).some(value => value !== "");
    });
    
    

    const saveButtonClicked = () => {
        let obj = formData;
        if(Object.keys(obj).length > 0){
            setFormDataArr([...formDataArr, {...obj}]);
        } 
        setConfirmSave('Yes');        
        // showSpinner();
    }

   

    useEffect(() => {
        // Check if the ref is defined and the button element exists
        if (confirmSave === 'Yes') {
          // Simulate a click event
          buttonRef.current.click();
        }
      }, [confirmSave]);

      console.log(finalFormDataArray);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(finalFormDataArray.length > 0  ){
            for(var i=0; i < finalFormDataArray.length; i++){
                showSpinner();
                axios.post('https://mglps.in/api/marks/addMarks.php', finalFormDataArray[i])
                .then((response) => {
                console.log('Marks added successfully:', response.data);
                })
                .catch((error) => {
                console.error('Error updating data:', error);
                // Handle errors here
                })
                .finally (() =>{
                    console.log('All marks entered')
                });
            }
            Swal.fire({
                icon: 'success',
                title: 'Marks Added',
                text: 'Assessment marks has been successfully added!',
            });
            refreshData();
            setModalBox(false);
            setFormData({});
            setFormDataArr([]);
            setSelectedAssessment('');
            setSubcode('');
            setMsg('');
            setConfirmSave('No');
            
        }else{
            console.log("No data to post...")
        }
    };

  return (
    <>
            <Modal  onHide={setModalBox} size='xl' show={modalBox} centered>
            {isLoading && <Spinner />}
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Assessment Mark Entry Form</h5>
                        <button type="button" className="btn-close"  onClick={onCloseModalClicked}></button>
                    </div>
                    <div className="modal-body">
                        <div className="card-body">                            
                            <InputGroup className="mb-3">                                
                                <InputGroup.Text id="basic-addon1">Enter code</InputGroup.Text>
                                <Form.Control
                                    placeholder="subject code"
                                    aria-label="Subject Code"
                                    aria-describedby="basic-addon1"
                                    value={subcode}
                                    onChange={handleSubcode}
                                /> 
                                <Form.Select aria-label="Default select example" onChange={selectOption}>
                                    <option>---Select assessment---</option>
                                    {assessment.map((item) => 
                                        <option value={item.assessment_name}>{item.assessment_name}</option>
                                    )}                                    
                                </Form.Select>                            
                            </InputGroup>
                            {msg !==''
                                ?<h3>{msg}</h3>
                                :(<>
                                {selectedClass.length > 0 
                                    ?(
                                    <Table striped>
                                            <thead>
                                                <tr>
                                                    <th>SUID</th>
                                                    <th>NAME</th>
                                                    <th>CLASS/ROLLNo</th>
                                                    <th>SUBJECT</th>
                                                    <th>CODE</th>
                                                    <th>SCORE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedClass.map(item =>
                                                <>
                                                {uid !== item.suid
                                                    ?(<tr key={item.suid}>
                                                        <td>{item.suid}</td>
                                                        <td>{item.student}</td>
                                                        <td>{item.grade}-({item.rollno})</td>
                                                        <td>{item.subjects}</td>
                                                        <td>{item.code}</td>
                                                        <td>
                                                            <Row>
                                                                <Col sm="14">
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="0" 
                                                                        onClick={() => onInputClicked(item.suid)}
                                                                        onKeyDown={() => onInputClicked(item.suid)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                    )
                                                    :(  <tr key={item.suid}>
                                                        <td>{item.suid}</td>
                                                        <td>{item.student}</td>
                                                        <td>{item.grade}-({item.rollno})</td>
                                                        <td>{item.subjects}</td>
                                                        <td>{item.code}</td>
                                                        <td>
                                                            <Row>
                                                                <Col sm="14">
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="Zero" 
                                                                        name='score'
                                                                        onChange={handleChange}
                                                                        required
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                    )
                                                }                                          
                                                </>
                                                )}                                                                      
                                            </tbody>
                                            </Table>
                                    )
                                    :'NO CODE FOUND'
                                }
                           </>)
                           }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success light" onClick={saveButtonClicked}>Save</button>
                        <button type="button" className="btn btn-success light" ref={buttonRef} style={{display:"none"}} onClick={handleSubmit}>confirm save</button>
                        <button type="button" className="btn btn-danger light" onClick={onCloseModalClicked}>Close</button>
                    </div>
                </div>                
            </Modal>
        </>
  )
})

export default AddMarkModal
