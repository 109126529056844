import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import  ThemeContext  from "./context/ThemeContext"; 
import { FeeProvider } from './context/FeeContext';
import { DataProvider } from './context/DataContext';
import { AcademicProvider } from './context/AcademicContext';
import { StudentsProvider } from './context/StudentsContext';
import { GradeProvider } from './context/GradeContext';
import { SfbProvider } from './context/SfbContext';
import { SubjectProvider } from './context/SubjectContext';
import { SBSCProvider } from './context/SbscContext';
import { SpinnerProvider } from './context/SpinnerContext';
import { CyamProvider } from './context/CyamContext';
import { AuthProvider } from './context/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store = {store}>
      <BrowserRouter basename='/'>
        <ThemeContext>
          <DataProvider>
            <FeeProvider>
              <AcademicProvider>
                <StudentsProvider>
                  <GradeProvider>
                    <SfbProvider>
                      <SubjectProvider>
                        <SBSCProvider>
                          <SpinnerProvider>
                            <CyamProvider>
                            <AuthProvider>
                              <App />
                            </AuthProvider>                              
                            </CyamProvider>
                          </SpinnerProvider>
                        </SBSCProvider>
                      </SubjectProvider>
                    </SfbProvider>
                  </GradeProvider>
                </StudentsProvider>
              </AcademicProvider>
            </FeeProvider>
          </DataProvider>
        </ThemeContext>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();