// src/FeeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';


const FeeContext = createContext();

export function FeeProvider({ children }) {
  const [feesData, setFeesData] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://mglps.in/api/fees/getFeeStructures.php');
      const data = await response.json();
      setFeesData(data);
    } catch (error) {
      console.error('Error fetching fee data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refreshData = () => {
    fetchData();
  };

  console.log(feesData);

  return (
    <FeeContext.Provider value={{ feesData, loading, refreshData }}>
      {children}
    </FeeContext.Provider>
  );
}

export function useFees() {
  const context = useContext(FeeContext);
  if (context === undefined) {
    throw new Error('useFees must be used within a FeeProvider');
  }
  return context;
}
